var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field",class:{
    'field--light': _vm.theme === 'light',
    'field--simple': _vm.simple,
    'field--full': _vm.full,
    'field--small': _vm.small,
  }},[_c('input',_vm._b({staticClass:"input",attrs:{"type":"checkbox","name":_vm.name,"id":_vm.name},domProps:{"checked":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.checked)}}},'input',_vm.$attrs,false)),_vm._v(" "),_c('label',{staticClass:"label",class:{
      'show-remove-icon': _vm.showRemoveIcon,
      'show-add-icon': _vm.showAddIcon,
    },attrs:{"for":_vm.name},on:{"mouseleave":_vm.mouseLeave}},[_vm._t("default"),_vm._v(" "),_c('span',{staticClass:"label__icon"})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }