<template>
  <input
    class="base-input-plain"
    :type="type"
    v-bind="$attrs"
    v-on="inputListeners"
    :class="{
      'base-input-plain--light': theme === 'light',
      'base-input-plain--error': error,
      'base-input-plain--has-value': hasValue,
    }"
  />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    error: Boolean,
    theme: {
      type: String,
      default: 'light',
      validator: function(value) {
        return ['white', 'light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    hasValue() {
      return this.$attrs.value !== '';
    },
    inputListeners() {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit('input', event.target.value);
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import './BaseInputPlain';
</style>
