<template>
  <div ref="wrapper" class="transition-all duration-[250ms]">
    <div ref="wrapperChild">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseWrapperInjectElement',
  data() {
    return {
      observer: null,
    };
  },
  mounted() {
    this.initResizeObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    initResizeObserver() {
      this.$refs.wrapper.style.transition = 'height 200ms ease-in-out';
      this.observer = new ResizeObserver(entries => {
        const entry = entries[0];
        const { height } = entry.contentRect;
        this.$refs.wrapper.style.height = `${height}px`;
      });

      if (this.$refs.wrapperChild) {
        this.observer.observe(this.$refs.wrapperChild);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
