import { redirects301 } from './redirects301';

export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home/Home'),
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/Login/Login'),
  },
  {
    path: '/password/reset',
    name: 'passwordReset',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/Login/Login'),
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'passwordResetConfirm',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/Login/Login'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/Signup/Signup'),
  },
  {
    path: '/activate/:uid/:token',
    name: 'signupActivate',
    component: () => import('@/views/SignupActivation/SignupActivation'),
  },
  {
    path: '/survey/:id*',
    name: 'survey',
    component: () => import('@/views/Survey/Survey'),
    meta: { private: true },
  },
  {
    path: '/app',
    component: () => import('@/views/App/App'),
    redirect: { name: 'legislation' },
    children: [
      {
        path: 'leges',
        name: 'legislation',
        component: () => import('@/views/Legislation/Legislation'),
        meta: {
          relation: 'legislation',
          redirect: 'news-legislation',
        },
      },
      {
        path: 'leges/:slug',
        name: 'legislation-item',
        component: () => import('@/views/LegislationItem/LegislationItem'),
        meta: { relation: 'legislation' },
      },
      // {
      //   path: 'search/leges',
      //   name: 'search-legislation',
      //   component: () => import('@/views/Search/pages/SearchLegislation'),
      //   meta: { private: true, search: true, relation: 'legislation' },
      // },
      {
        path: 'regulations',
        name: 'regulations',
        component: () => import('@/views/Regulations/Regulations'),
        meta: {
          relation: 'regulations',
          redirect: 'news-regulations',
        },
      },
      {
        path: 'regulations/:slug',
        name: 'regulations-item',
        component: () => import('@/views/RegulationsItem/RegulationsItem'),
        meta: { relation: 'regulations' },
      },
      // {
      //   path: 'search/regulations',
      //   name: 'search-regulations',
      //   component: () => import('@/views/Search/pages/SearchRegulations'),
      //   meta: { private: true, search: true, relation: 'regulations' },
      // },
      {
        path: 'p-m-ordinances',
        name: 'p-m-ordinances',
        component: () => import('@/views/PMOrdinances/PMOrdinances'),
        meta: {
          relation: 'p-m-ordinances',
          redirect: 'news-p-m-ordinances',
        },
      },
      {
        path: 'p-m-ordinances/:slug',
        name: 'p-m-ordinances-item',
        component: () => import('@/views/PMOrdinancesItem/PMOrdinancesItem'),
        meta: { relation: 'p-m-ordinances' },
      },
      // {
      //   path: 'search/p-m-ordinances',
      //   name: 'search-p-m-ordinances',
      //   component: () => import('@/views/Search/pages/SearchPMOrdinances'),
      //   meta: { private: true, search: true, relation: 'p-m-ordinances' },
      // },
      {
        path: 'parliament-resolutions',
        name: 'parliament-resolutions',
        component: () =>
          import('@/views/ParliamentResolutions/ParliamentResolutions'),
        meta: {
          relation: 'parliament-resolutions',
          redirect: 'news-parliament-resolutions',
        },
      },
      {
        path: 'parliament-resolutions/:slug',
        name: 'parliament-resolutions-item',
        component: () =>
          import('@/views/ParliamentResolutionsItem/ParliamentResolutionsItem'),
        meta: { relation: 'parliament-resolutions' },
      },
      // {
      //   path: 'search/parliament-resolutions',
      //   name: 'search-parliament-resolutions',
      //   component: () =>
      //     import('@/views/Search/pages/SearchParliamentResolutions'),
      //   meta: {
      //     private: true,
      //     search: true,
      //     relation: 'parliament-resolutions',
      //   },
      // },
      {
        path: 'gov-resolutions',
        name: 'gov-resolutions',
        component: () => import('@/views/GovResolutions/GovResolutions'),
        meta: {
          relation: 'gov-resolutions',
          redirect: 'news-gov-resolutions',
        },
      },
      {
        path: 'gov-resolutions/:slug',
        name: 'gov-resolutions-item',
        component: () =>
          import('@/views/GovResolutionsItem/GovResolutionsItem'),
        meta: { relation: 'gov-resolutions' },
      },
      // {
      //   path: 'search/gov-resolutions',
      //   name: 'search-gov-resolutions',
      //   component: () => import('@/views/Search/pages/SearchGovResolutions'),
      //   meta: { private: true, search: true, relation: 'gov-resolutions' },
      // },
      {
        path: 'european-union-acts',
        name: 'european-union-acts',
        component: () => import('@/views/EuropeanUnionActs/EuropeanUnionActs'),
        meta: {
          relation: 'european-union-acts',
          redirect: 'news-european-union-acts',
        },
      },
      {
        path: 'european-union-acts/:slug',
        name: 'european-union-acts-item',
        component: () =>
          import('@/views/EuropeanUnionActsItem/EuropeanUnionActsItem'),
        meta: { relation: 'european-union-acts' },
      },
      // {
      //   path: 'search/european-union-acts',
      //   name: 'search-european-union-acts',
      //   component: () => import('@/views/Search/pages/SearchEuropeanUnionActs'),
      //   meta: { private: true, search: true, relation: 'european-union-acts' },
      // },
      {
        path: 'european-union-regulations',
        name: 'european-union-regulations',
        component: () =>
          import('@/views/EuropeanUnionRegulations/EuropeanUnionRegulations'),
        meta: {
          relation: 'european-union-regulations',
          redirect: 'news-european-union-regulations',
        },
      },
      {
        path: 'european-union-regulations/:slug',
        name: 'european-union-regulations-item',
        component: () =>
          import(
            '@/views/EuropeanUnionRegulationsItem/EuropeanUnionRegulationsItem'
          ),
        meta: { relation: 'european-union-regulations' },
      },
      // {
      //   path: 'search/european-union-regulations',
      //   name: 'search-european-union-regulations',
      //   component: () => import('@/views/Search/pages/SearchEuropeanUnionActs'),
      //   meta: {
      //     private: true,
      //     search: true,
      //     relation: 'european-union-regulations',
      //   },
      // },
      {
        path: 'european-union-directives',
        name: 'european-union-directives',
        component: () =>
          import('@/views/EuropeanUnionDirectives/EuropeanUnionDirectives'),
        meta: {
          relation: 'european-union-directives',
          redirect: 'news-european-union-directives',
        },
      },
      {
        path: 'european-union-directives/:slug',
        name: 'european-union-directives-item',
        component: () =>
          import(
            '@/views/EuropeanUnionDirectivesItem/EuropeanUnionDirectivesItem'
          ),
        meta: { relation: 'european-union-directives' },
      },
      // {
      //   path: 'search/european-union-directives',
      //   name: 'search-european-union-directives',
      //   component: () => import('@/views/Search/pages/SearchEuropeanUnionActs'),
      //   meta: {
      //     private: true,
      //     search: true,
      //     relation: 'european-union-directives',
      //   },
      // },
      {
        path: 'european-union-decisions-and-others',
        name: 'european-union-decisions-and-others',
        component: () =>
          import(
            '@/views/EuropeanUnionDecisionsAndOthers/EuropeanUnionDecisionsAndOthers'
          ),
        meta: {
          relation: 'european-union-decisions-and-others',
          redirect: 'news-european-union-decisions-and-others',
        },
      },
      {
        path: 'european-union-decisions-and-others/:slug',
        name: 'european-union-decisions-and-others-item',
        component: () =>
          import(
            '@/views/EuropeanUnionDecisionsAndOthersItem/EuropeanUnionDecisionsAndOthersItem'
          ),
        meta: { relation: 'european-union-decisions-and-others' },
      },
      // {
      //   path: 'search/european-union-decisions-and-others',
      //   name: 'search-european-union-decisions-and-others',
      //   component: () => import('@/views/Search/pages/SearchEuropeanUnionActs'),
      //   meta: {
      //     private: true,
      //     search: true,
      //     relation: 'european-union-decisions-and-others',
      //   },
      // },
      {
        path: 'healthfund',
        name: 'healthfund',
        component: () => import('@/views/Healthfund/Healthfund'),
        meta: { private: true, relation: 'healthfund' },
      },
      {
        path: 'healthfund/:slug',
        name: 'healthfund-item',
        component: () => import('@/views/HealthfundItem/HealthfundItem'),
        meta: { private: true, relation: 'healthfund' },
      },
      // {
      //   path: 'search/healthfund',
      //   name: 'search-healthfund',
      //   component: () => import('@/views/Search/pages/SearchHealthfund'),
      //   meta: { private: true, search: true, relation: 'healthfund' },
      // },
      {
        path: 'gif',
        name: 'gif',
        component: () => import('@/views/Gif/Gif'),
        meta: { private: true, relation: 'gif' },
      },
      {
        path: 'gif/:slug',
        name: 'gif-item',
        component: () => import('@/views/GifItem/GifItem'),
        meta: { private: true, relation: 'gif' },
      },
      // {
      //   path: 'search/gif',
      //   name: 'search-gif',
      //   component: () => import('@/views/Search/pages/SearchGif'),
      //   meta: { private: true, search: true, relation: 'gif' },
      // },
      {
        path: 'news',
        name: 'news',
        component: () => import('@/views/News/News'),
        meta: { private: false },
        children: [
          {
            path: 'leges',
            name: 'news-legislation',
            component: () => import('@/views/Legislation/Legislation'),
            meta: { relation: 'legislation' },
          },
          {
            path: 'regulations',
            name: 'news-regulations',
            component: () => import('@/views/Regulations/Regulations'),
            meta: { relation: 'regulations' },
          },
          {
            path: 'healthfund',
            name: 'news-healthfund',
            component: () => import('@/views/Healthfund/Healthfund'),
            meta: { relation: 'healthfund' },
          },
          {
            path: 'p-m-ordinances',
            name: 'news-p-m-ordinances',
            component: () => import('@/views/PMOrdinances/PMOrdinances'),
            meta: { relation: 'p-m-ordinances' },
          },
          {
            path: 'gov-resolutions',
            name: 'news-gov-resolutions',
            component: () => import('@/views/GovResolutions/GovResolutions'),
            meta: { relation: 'gov-resolutions' },
          },
          {
            path: 'parliament-resolutions',
            name: 'news-parliament-resolutions',
            component: () =>
              import('@/views/ParliamentResolutions/ParliamentResolutions'),
            meta: { relation: 'parliament-resolutions' },
          },
          {
            path: 'european-union-regulations',
            name: 'news-european-union-regulations',
            component: () =>
              import(
                '@/views/EuropeanUnionRegulations/EuropeanUnionRegulations'
              ),
            meta: { relation: 'european-union-regulations' },
          },
          {
            path: 'european-union-directives',
            name: 'news-european-union-directives',
            component: () =>
              import('@/views/EuropeanUnionDirectives/EuropeanUnionDirectives'),
            meta: { relation: 'european-union-directives' },
          },
          {
            path: 'european-union-decisions-and-others',
            name: 'news-european-union-decisions-and-others',
            component: () =>
              import(
                '@/views/EuropeanUnionDecisionsAndOthers/EuropeanUnionDecisionsAndOthers'
              ),
            meta: { relation: 'european-union-decisions-and-others' },
          },
          {
            path: 'gif',
            name: 'gif',
            component: () => import('@/views/Gif/Gif'),
            meta: { relation: 'gif' },
          },
          {
            path: '*',
            redirect: { name: 'news-legislation' },
          },
        ],
      },
      {
        path: 'my-monitoring',
        name: 'my-monitoring',
        component: () => import('@/views/Monitoring/Monitoring'),
        meta: { private: true },
        children: [
          {
            path: 'leges',
            name: 'my-monitoring-legislation',
            component: () =>
              import('@/views/Monitoring/pages/MonitoringLegislation'),
            meta: { relation: 'legislation' },
          },
          {
            path: 'regulations',
            name: 'my-monitoring-regulations',
            component: () =>
              import('@/views/Monitoring/pages/MonitoringRegulations'),
            meta: { relation: 'regulations' },
          },
          {
            path: 'p-m-ordinances',
            name: 'my-monitoring-p-m-ordinances',
            component: () =>
              import('@/views/Monitoring/pages/MonitoringPMOrdinances'),
            meta: { relation: 'p-m-ordinances' },
          },
          {
            path: 'gov-resolutions',
            name: 'my-monitoring-gov-resolutions',
            component: () =>
              import('@/views/Monitoring/pages/MonitoringGovResolutions'),
            meta: { relation: 'gov-resolutions' },
          },
          {
            path: 'parliament-resolutions',
            name: 'my-monitoring-parliament-resolutions',
            component: () =>
              import(
                '@/views/Monitoring/pages/MonitoringParliamentResolutions'
              ),
            meta: { relation: 'parliament-resolutions' },
          },
          {
            path: 'european-union-acts',
            name: 'my-monitoring-european-union-acts',
            component: () =>
              import('@/views/Monitoring/pages/MonitoringEuropeanUnionActs'),
            meta: { relation: 'european-union-acts' },
          },
          {
            path: 'healthfund',
            name: 'my-monitoring-healthfund',
            component: () =>
              import('@/views/Monitoring/pages/MonitoringHealthfund'),
            meta: { relation: 'healthfund' },
          },
          {
            path: 'european-union-regulations',
            name: 'my-monitoring-european-union-regulations',
            component: () =>
              import(
                '@/views/Monitoring/pages/MonitoringEuropeanUnionRegulations'
              ),
            meta: { relation: 'european-union-regulations' },
          },
          {
            path: 'european-union-directives',
            name: 'my-monitoring-european-union-directives',
            component: () =>
              import(
                '@/views/Monitoring/pages/MonitoringEuropeanUnionDirectives'
              ),
            meta: { relation: 'european-union-directives' },
          },
          {
            path: 'european-union-decisions-and-others',
            name: 'my-monitoring-european-union-decisions-and-others',
            component: () =>
              import(
                '@/views/Monitoring/pages/MonitoringEuropeanUnionDecisionsAndOthers'
              ),
            meta: { relation: 'european-union-decisions-and-others' },
          },
          {
            path: '*',
            redirect: { name: 'my-monitoring-legislation' },
          },
        ],
      },
      {
        path: 'my-monitoring-ue',
        name: 'my-monitoring-ue',
        component: () => import('@/views/Monitoring/MonitoringUE'),
        meta: { private: true },
        children: [
          // {
          //   path: 'european-union-regulations',
          //   name: 'my-monitoring-european-union-regulations',
          //   component: () =>
          //     import(
          //       '@/views/Monitoring/pages/MonitoringEuropeanUnionRegulations'
          //     ),
          //   meta: { relation: 'european-union-regulations' },
          // },
          // {
          //   path: 'european-union-directives',
          //   name: 'my-monitoring-european-union-directives',
          //   component: () =>
          //     import(
          //       '@/views/Monitoring/pages/MonitoringEuropeanUnionDirectives'
          //     ),
          //   meta: { relation: 'european-union-directives' },
          // },
          // {
          //   path: 'european-union-decisions-and-others',
          //   name: 'my-monitoring-european-union-decisions-and-others',
          //   component: () =>
          //     import(
          //       '@/views/Monitoring/pages/MonitoringEuropeanUnionDecisionsAndOthers'
          //     ),
          //   meta: { relation: 'european-union-decisions-and-others' },
          // },
        ],
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('@/views/Account/Account'),
        meta: { private: true },
      },
    ],
  },
  ...redirects301,
  {
    path: '*',
    redirect: '/',
  },
];
