<template>
  <div>
    <div class="more" v-if="disabled || isOpen">
      <slot />
    </div>
    <div :class="{ 'text-center': centerToggle }">
      <button
        v-if="!disabled"
        @click="isOpen = !isOpen"
        class="toggle"
        :class="{ 'toggle--open': isOpen }"
      >
        {{ isOpen ? this.openText || 'mniej' : this.closedText || 'więcej' }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disableAbove: String,
    closedText: String,
    openText: String,
    centerToggle: Boolean,
  },
  data() {
    return {
      isOpen: false,
      disabled: false,
    };
  },
  created() {
    if (this.disableAbove) {
      window.addEventListener('resize', this.onResize, { passive: true });
      this.onResize();
    }
  },
  destroyed() {
    if (this.disableAbove) {
      window.removeEventListener('resize', this.onResize);
    }
  },
  methods: {
    onResize() {
      this.disabled = this.$util.mq(this.disableAbove);
    },
  },
};
</script>

<style lang="scss" scoped>
.more {
  animation: fadeInBottom 0.5s $easeOutCubic;
}
.toggle {
  color: $gray-2;

  &::after {
    @include arrowPseudo($gray-3, 'down');
    display: inline-block;
    margin-left: 0.7rem;
    margin-bottom: 0.4em;
  }
}
.toggle--open::after {
  margin-bottom: 0;
  margin-top: 0.2em;
  transform: rotate(-45deg);
}
</style>
