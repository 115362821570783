export const cookies = {
  required: {
    title: 'Niezbędne pliki cookie',
    description:
      'Te pliki cookie umożliwiają działanie podstawowych funkcji, takie jak zabezpieczenia i prawidłowe wyświetlanie strony. Pliki te mogą być instalowane w szczególności na potrzeby zapamiętywania sesji logowania lub wypełniania formularzy, a także w celach związanych ustawieniem opcji prywatności. Tych plików cookie nie można wyłączyć.',
    default: true,
    disabled: true,
  },
  analytics: {
    title: 'Analityczne pliki cookie',
    description:
      'Te pliki cookie umożliwiają sprawdzenie liczby wizyt i źródeł ruchu w naszym serwisie. Pomagają nam ustalić, które strony są najbardziej popularne. W ten sposób badamy statystki ruchu na stronie i poprawiamy wydajność naszego serwisu. Informacje zbierane przez te pliki cookie nie mają na celu ustalenie tożsamości poszczególnych użytkowników. Jeśli nie zezwolisz na stosowanie tych plików cookie, informacje o odiwedzonych przez Ciebie podstronach serwisu nie zostaną uwzględnione  naszych statystykach.',
    default: false,
  },
};

export const defaultCookies = (state = null) => {
  const accepted = {};
  for (const cookie in cookies) {
    if (state !== null) {
      accepted[cookie] = state;
    } else {
      accepted[cookie] = cookies[cookie].default || false;
    }
  }
  return accepted;
};

export const allAccepted = defaultCookies(true);
export const onlyRequired = defaultCookies();
