<template>
  <div
    class="spinner"
    :class="{
      small,
      large,
      gray,
    }"
  />
</template>

<script>
export default {
  props: {
    small: Boolean,
    large: Boolean,
    gray: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  z-index: 100;
  margin-inline: 10px;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: $primary;
    animation: rotate 1s linear infinite;
  }
  &.small {
    width: 1rem;
    height: 1rem;
  }
  &.large {
    width: 3rem;
    height: 3rem;

    &::before {
      border-width: 3px;
    }
  }
  &.gray {
    &::before {
      border-top-color: $gray-4;
    }
  }
}
</style>
