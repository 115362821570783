import { api } from '../api/api';

const urls = {
  legislation: {
    get: 'v1/list_of_favorite_leges_pk/',
    toggle(id) {
      return `v0/lex/toggle_favorites/${id}`;
    },
  },
  regulations: {
    get: 'v1/list_of_favorite_regulations_pk/',
    toggle(id) {
      return `v0/regulations/toggle_favorites/${id}`;
    },
  },
  'p-m-ordinances': {
    get: 'v1/list_of_favorite_pm_ordinances_pk/',
    toggle(id) {
      return `v0/pm_ordinances/toggle_favorites/${id}`;
    },
  },
  'gov-resolutions': {
    get: 'v1/list_of_favorite_gov_resolutions_pk/',
    toggle(id) {
      return `v0/gov_resolutions/toggle_favorites/${id}`;
    },
  },
  'parliament-resolutions': {
    get: 'v1/list_of_favorite_parliament_resolutions_pk/',
    toggle(id) {
      return `v0/parliment_resolutions/toggle_favorites/${id}`;
    },
  },
  'european-union-acts': {
    get: 'v1/list_of_favorite_european_union_acts_pk/',
    toggle(id) {
      return `v0/european_union_acts/toggle_favorites/${id}`;
    },
  },
  'european-union-regulations': {
    get: 'v1/list_of_favorite_european_union_acts_pk/',
    toggle(id) {
      return `v0/european_union_acts/toggle_favorites/${id}`;
    },
  },
  'european-union-directives': {
    get: 'v1/list_of_favorite_european_union_acts_pk/',
    toggle(id) {
      return `v0/european_union_acts/toggle_favorites/${id}`;
    },
  },
  'european-union-decisions-and-others': {
    get: 'v1/list_of_favorite_european_union_acts_pk/',
    toggle(id) {
      return `v0/european_union_acts/toggle_favorites/${id}`;
    },
  },
  healthfund: {
    get: 'v1/list_of_favorite_nfz_pk/',
    toggle(id) {
      return `v0/healthfund/toggle_favorites/${id}`;
    },
  },
};

const initialState = {
  legislation: null,
  regulations: null,
  'p-m-ordinances': null,
  'gov-resolutions': null,
  'parliament-resolutions': null,
  'european-union-acts': null,
  'european-union-regulations': null,
  'european-union-directives': null,
  'european-union-decisions-and-others': null,
  healthfund: null,
};
const getInitialState = () => JSON.parse(JSON.stringify(initialState));

export const bookmarks = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    isBookmark: state => ({ type, id }) => {
      return state[type] && state[type].includes(Number(id));
    },
    bookmarks: state => type => state[type],
  },
  mutations: {
    setBookmarks(state, { type, bookmarks }) {
      state[type] = bookmarks;
    },
    resetBookmarks(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    reset({ commit }) {
      commit('resetBookmarks');
    },
    async fetchBookmarks(
      { state, commit, rootGetters },
      { type, responseField }
    ) {
      if (!rootGetters['user/isLoggedIn']) {
        return;
      }
      const { data } = await api.get(urls[type].get, {
        cache: {
          ignoreCache: true,
        },
      });
      const bookmarks = data.results[0] ? data.results[0][responseField] : [];
      commit('setBookmarks', { type, bookmarks });
    },
    fetchLegislation({ dispatch }) {
      return dispatch('fetchBookmarks', {
        type: 'legislation',
        responseField: 'favorite_leges_pk',
      });
    },
    fetchRegulations({ dispatch }) {
      return dispatch('fetchBookmarks', {
        type: 'regulations',
        responseField: 'favorite_regulations_pk',
      });
    },
    fetchGovResolutions({ dispatch }) {
      return dispatch('fetchBookmarks', {
        type: 'gov-resolutions',
        responseField: 'favorite_gov_resolutions_pk',
      });
    },
    fetchParliamentResolutions({ dispatch }) {
      return dispatch('fetchBookmarks', {
        type: 'parliament-resolutions',
        responseField: 'favorite_parliment_resolutions_pk',
      });
    },
    fetchPMOrdinances({ dispatch }) {
      return dispatch('fetchBookmarks', {
        type: 'p-m-ordinances',
        responseField: 'favorite_pm_ordinances_pk',
      });
    },
    fetchEuropeanUnionActs({ dispatch }) {
      return dispatch('fetchBookmarks', {
        type: 'european-union-acts',
        responseField: 'favorite_european_union_acts_pk',
      });
    },
    fetchEuropeanUnionRegulations({ dispatch }) {
      return dispatch('fetchBookmarks', {
        type: 'european-union-regulations',
        responseField: 'favorite_european_union_acts_pk',
      });
    },
    fetchEuropeanUnionDirectives({ dispatch }) {
      return dispatch('fetchBookmarks', {
        type: 'european-union-directives',
        responseField: 'favorite_european_union_acts_pk',
      });
    },
    fetchEuropeanUnionDecisionsAndOthers({ dispatch }) {
      return dispatch('fetchBookmarks', {
        type: 'european-union-decisions-and-others',
        responseField: 'favorite_european_union_acts_pk',
      });
    },
    fetchHealthfund({ dispatch }) {
      return dispatch('fetchBookmarks', {
        type: 'healthfund',
        responseField: 'favorite_ordinances_pk',
      });
    },
    async toggleBookmark(
      { commit, state, dispatch },
      { type, id, method = null }
    ) {
      if (!state[type]) {
        return;
      }
      const exists = state[type].includes(Number(id));
      if (!method) {
        const bookmarks = exists
          ? state[type].filter(bookmark => bookmark !== id)
          : state[type].concat([id]);
        commit('setBookmarks', { type, bookmarks });
      } else {
        commit('setBookmarks', { type, bookmarks: state[type].concat([id]) });
      }

      switch (true) {
        case exists && !method:
          method = 'delete';
          break;
        case !exists && !method:
          method = 'put';
          break;
        default:
          break;
      }

      await api[method](urls[type].toggle(id));
      if (method === 'put') {
        commit('list/clearExcludedFromModule', id, { root: true });
      }
      dispatch('list/getFavoriteHated', null, { root: true });
    },
    toggleLegislation({ dispatch }, { id, method = null }) {
      return dispatch('toggleBookmark', { type: 'legislation', id, method });
    },
    toggleRegulations({ dispatch }, { id, method = null }) {
      return dispatch('toggleBookmark', { type: 'regulations', id, method });
    },
    toggleGovResolutions({ dispatch }, { id, method = null }) {
      return dispatch('toggleBookmark', {
        type: 'gov-resolutions',
        id,
        method,
      });
    },
    toggleParliamentResolutions({ dispatch }, { id, method = null }) {
      return dispatch('toggleBookmark', {
        type: 'parliament-resolutions',
        id,
        method,
      });
    },
    togglePMOrdinances({ dispatch }, { id, method = null }) {
      return dispatch('toggleBookmark', { type: 'p-m-ordinances', id, method });
    },
    toggleEuropeanUnionActs({ dispatch }, { id, method = null }) {
      return dispatch('toggleBookmark', {
        type: 'european-union-acts',
        id,
        method,
      });
    },
    toggleEuropeanUnionRegulations({ dispatch }, { id, method = null }) {
      return dispatch('toggleBookmark', {
        type: 'european-union-regulations',
        id,
        method,
      });
    },
    toggleEuropeanUnionDirectives({ dispatch }, { id, method = null }) {
      return dispatch('toggleBookmark', {
        type: 'european-union-directives',
        id,
        method,
      });
    },
    toggleEuropeanUnionDecisionsAndOthers({ dispatch }, { id, method = null }) {
      return dispatch('toggleBookmark', {
        type: 'european-union-decisions-and-others',
        id,
        method,
      });
    },
    toggleHealthfund({ dispatch }, { id, method = null }) {
      return dispatch('toggleBookmark', { type: 'healthfund', id, method });
    },
  },
};
