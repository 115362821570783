import Vue from 'vue';
import PortalVue from 'portal-vue';
import VueGtag from 'vue-gtag';
import Meta from 'vue-meta';

import './registerBaseComponents';
import './directives';
import { router } from './router/router';
import { store } from './store/store';
import { api } from './api/api';
import * as util from './util/util';
import { sessionBroadcast } from './sessionBroadcast';
import Main from './Main.vue';
import { getApiToken, setApiToken } from './api/token';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies, { expires: '1y' });

Vue.use(Meta);
Vue.use(PortalVue);
Vue.use(
  VueGtag,
  {
    config: { id: 'UA-143022636-1' },
    enabled: Vue.$cookies.get('cookies')?.analytics || false,
  },
  router
);

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$util = util;

store
  .dispatch('user/initialLogin')
  .catch(err => {
    console.warn('Session token expired:', err);
    store.dispatch('user/logout', {
      initial: true,
    });
  })
  .finally(() => {
    new Vue({
      router,
      store,
      render: h => h(Main),
    }).$mount('#app');
  });

sessionBroadcast.setup({
  updateToken(token) {
    if (token === getApiToken()) {
      return;
    }
    setApiToken(token);
    if (!store.getters['user/isLoggedIn']) {
      window.location.reload();
    }
  },
  sessionLogout() {
    store.dispatch('user/logout');
  },
  getSessionToken: getApiToken,
});

if (!getApiToken()) {
  sessionBroadcast.get();
}

// Calculate viewport size - mobile fix
function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
}

window.addEventListener('resize', appHeight);
appHeight();
