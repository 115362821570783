<template>
  <component
    :is="tag"
    :to="to"
    :type="$attrs.type || 'button'"
    class="base-button"
    :class="{
      [`base-button--${theme}`]: !plain,
      [`base-button--${theme}-plain`]: plain,
      [`base-button--${theme}-bordered`]: bordered,
      'base-button--loading': loading,
      'base-button--wide': wide,
      'base-button--bordered': bordered,
      'base-button--uppercase': uppercase,
      'base-button--full': full,
    }"
    @click="handleClick"
    :disabled="$attrs.disabled || loading"
    ref="button"
  >
    <span class="base-button__text">
      <slot></slot>
    </span>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    theme: {
      type: String,
      default: 'primary',
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    to: [String, Object],
    loading: Boolean,
    plain: Boolean,
    bordered: Boolean,
    wide: Boolean,
    full: Boolean,
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
    getHeight() {
      return this.$refs.button.offsetHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './BaseButton';
</style>
