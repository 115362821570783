<template>
  <textarea
    class="textarea"
    v-bind="$attrs"
    v-on="inputListeners"
    :class="{
      'textarea--light': theme === 'light',
      'textarea--error': error,
      'textarea--has-value': hasValue,
    }"
  ></textarea>
</template>

<script>
export default {
  props: {
    error: Boolean,
    theme: {
      type: String,
      default: 'dark',
      validator: function(value) {
        return ['white', 'light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    hasValue() {
      return this.$attrs.value !== '';
    },
    inputListeners() {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit('input', event.target.value);
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import './BaseTextarea';
</style>
