<template>
  <portal to="modal">
    <transition name="modal" @after-enter="modalOpen" @after-leave="modalClose">
      <div
        class="modal"
        :class="{
          'modal--full': fullHeight,
          'modal--overflowHidden': overflowHidden,
        }"
        v-if="open"
        v-escape="() => $emit('close')"
        ref="modalRef"
        @scroll="e => scrollListener(e)"
      >
        <div class="modal__outer">
          <button
            ref="modalContent"
            @click="$emit('close')"
            class="modal__close-bg"
          />
          <div class="modal__row">
            <div
              :class="[
                'modal__inner',
                {
                  'modal__inner--large': large,
                },
              ]"
              style="display: block"
            >
              <div class="modal__close-wrap">
                <base-button-exit
                  @click="$emit('close')"
                  :class="{ 'tablet-desktop': theme !== 'mobileCloseX' }"
                />
              </div>

              <slot />

              <div
                v-if="theme !== 'mobileCloseX'"
                class="modal__close-wrapper-mobile mobile"
              >
                <div @click="$emit('close')">
                  <img :src="require('@/assets/icons/CloseX.svg')" />
                  ukryj
                </div>
                <!--              <base-button-->
                <!--                v-if="theme !== 'mobileCloseX'"-->
                <!--                theme="gray-5"-->
                <!--                plain-->
                <!--                class="modal__close-mobile mobile"-->
                <!--                @click="$emit('close')"-->
                <!--                >zamknij</base-button-->
                <!--              >-->
              </div>
            </div>
            <div
              :class="[
                'modal__side-buttons',
                {
                  'modal__side-buttons--active': currentScrollTop >= 200,
                },
              ]"
            >
              <div>
                <div
                  v-if="showCloseSticky"
                  class="modal__close-sticky"
                  @click="$emit('close')"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div
                  v-if="showScrollTop"
                  class="modal__scroll-top"
                  @click="scrollTop"
                >
                  <img :src="require(`@/assets/icons/ArrowUpward.svg`)" />
                </div>
                <portal-target name="buttons-sidebar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    open: Boolean,
    large: Boolean,
    fullHeight: Boolean,
    theme: String,
    url: String,
    overflowHidden: {
      type: Boolean,
      default: false,
    },
    showScrollTop: {
      type: Boolean,
      default: false,
    },
    showCloseSticky: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.$route.query[this.url] === 'open') {
      this.$emit('forceOpen');
    }
    this.toggleScrollBlock();
  },
  data() {
    return {
      prevFocus: null,
      currentScrollTop: 0,
    };
  },
  computed: {
    ...mapGetters({
      isModalActive: 'isModalActive',
    }),
  },
  watch: {
    open(val, prevVal) {
      if (val === prevVal) {
        return;
      }
      this.toggleScrollBlock();
    },
    isModalActive(v) {
      if (!v) {
        this.$emit('close');
      }
    },
  },
  methods: {
    ...mapMutations({
      storeOpenedModal: 'openModal',
      storeClosedModal: 'closeModal',
    }),
    enableScroll() {
      this.$refs.modalRef.style.overflow = 'auto';
    },
    disableScroll() {
      this.$refs.modalRef.style.overflow = 'hidden';
      this.scrollTop({ behavior: 'auto' });
    },
    scrollListener(e) {
      this.currentScrollTop = e.target.scrollTop;
    },
    modalOpen() {
      this.prevFocus = document.activeElement;
      this.$refs.modalContent.focus();
      this.storeOpenedModal();
    },
    modalClose() {
      if (!this.prevFocus) {
        return;
      }
      this.prevFocus.focus();
      this.prevFocus = null;
      this.storeClosedModal();
    },
    toggleScrollBlock() {
      if (this.open) {
        this.$store.dispatch('blockScroll');
      } else {
        this.$store.dispatch('unblockScroll');
      }
    },
    scrollTop({ behavior = 'smooth' }) {
      this.$refs.modalRef.scrollTo({
        top: 0,
        behavior,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'BaseModal';
</style>
