const STORAGE_KEY = 'session';
const GET_STORAGE_KEY = 'get-session';
const LOGOUT_KEY = 'session-logout';

export const sessionBroadcast = {
  setup({ updateToken, sessionLogout, getSessionToken }) {
    window.addEventListener('storage', function(event) {
      if (!event.newValue) {
        return;
      }
      const token = getSessionToken();
      switch (event.key) {
        case GET_STORAGE_KEY:
          if (token) {
            localStorage.setItem(STORAGE_KEY, token);
            localStorage.removeItem(STORAGE_KEY);
          }
          break;
        case STORAGE_KEY:
          updateToken(event.newValue);
          break;
        case LOGOUT_KEY:
          sessionLogout();
          break;
      }
    });
  },
  post(token) {
    window.localStorage.setItem(STORAGE_KEY, token);
    window.localStorage.removeItem(STORAGE_KEY, token);
  },
  get() {
    window.localStorage.setItem(GET_STORAGE_KEY, 'get');
    window.localStorage.removeItem(GET_STORAGE_KEY, 'get');
  },
  logout() {
    window.localStorage.setItem(LOGOUT_KEY, 'logout');
    window.localStorage.removeItem(LOGOUT_KEY, 'logout');
  },
};
