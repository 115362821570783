export const redirects301 = [
  { path: '/lex/:id', redirect: '/app/leges/:id' },
  { path: '/news', redirect: '/app/leges' },
  { path: '/my_query', redirect: '/app/my-monitoring/leges' },
  {
    path: '/regulations/regulations/:slug',
    redirect: '/app/regulations/:slug',
  },
  { path: '/regulations/reg_news', redirect: '/app/regulations' },
  {
    path: '/regulations/reg_my_query',
    redirect: '/app/my-monitoring/regulations',
  },
  { path: '/healthfund/nfz_news', redirect: '/app/healthfund' },
  { path: '/healthfund/healthfund/:slug', redirect: '/app/healthfund/:slug' },
  { path: '/accounts/login', redirect: '/' },
  { path: '/gif/gif_news', redirect: '/app/gif' },
  { path: '/gif/gif/:id', redirect: '/app/gif/:id' },
];
