const sliceOut = (text, index) => text.slice(0, index) + text.slice(index + 2);

const sanitizeHighlights = highlights =>
  highlights.map(highlight => highlight.replace(/[-[\]{}*+?.,^$#]/g, '\\$&'));

const markRaw = (text, highlights) => {
  let out = text;
  const counts = [];

  highlights.forEach(highlightRaw => {
    const highlight = highlightRaw.replace('\\s', ' ');
    const startsWithSpace = highlight[0] === ' ';
    const regex = new RegExp(
      startsWithSpace
        ? ` (${highlight.trim()}[^\\s,.;:!?<]*)`
        : `([^ >]*${highlight}[^\\s,.;:!?<]*)`,
      'gi'
    );

    let matchCount = 0;

    out = out.replace(regex, (match, p1) => {
      matchCount++;
      return startsWithSpace ? ` {|${p1}|}` : `{|${p1}|}`;
    });

    if (matchCount > 0) {
      const existing = counts.find(item => item.word === highlightRaw);
      if (existing) {
        existing.count += matchCount;
      } else {
        counts.push({
          active: true,
          word: highlightRaw,
          count: matchCount,
        });
      }
    }
  });

  return { out, counts };
};

const removeMarksWithinMarks = text => {
  let out = text;
  const forDeletion = [];
  let open = 0;
  for (let i = 0; i < out.length - 1; i++) {
    if (out[i] + out[i + 1] === '{|') {
      if (open > 0) {
        forDeletion.unshift(i);
      }
      open++;
    } else if (out[i] + out[i + 1] === '|}') {
      open--;
      if (open > 0) {
        forDeletion.unshift(i);
      }
    }
  }
  forDeletion.forEach(i => {
    out = sliceOut(out, i);
  });
  return out;
};

export const markHighlights = (
  text,
  highlightsRaw = [],
  highlightSearch = null,
  returnCounts = false
) => {
  if (highlightsRaw.length === 0 && !highlightSearch) {
    return text;
  }
  const highlights = sanitizeHighlights(highlightsRaw);
  highlights.sort((a, b) => b.length - a.length);
  const { out: markedText, counts: markedTextCounts } = markRaw(
    text,
    highlights
  );
  let out = removeMarksWithinMarks(markedText);
  let matchHighlightSearchCount = 0;

  if (highlightSearch) {
    const regex = new RegExp(highlightSearch, 'gi');

    out = out.replace(regex, match => {
      matchHighlightSearchCount++;
      return `<span class="highlight highlight--search highlight--clickable" data-v-highlight-type="search">${match}</span>`;
    });
  }
  out = out.replace(/{\|(.*?)\|}/g, (match, word) => {
    return `<span
            class="highlight highlight--clickable"
            data-v-highlight-type="monitored"
            data-v-highlight-activ="true"
            data-v-highlight-word="${word}"
    >${word}</span>`;
  });
  return returnCounts
    ? { out, markedTextCounts, matchHighlightSearchCount }
    : out;
};

export const markHighlightsWithCount = (
  text,
  highlightsRaw = [],
  index = 0,
  highlightSearchRaw = ''
) => {
  // if (highlightsRaw.length === 0) {
  //   return { text };
  // }

  const counts = {};
  const indexes = [];
  let countSearch = 0;

  if (highlightSearchRaw) {
    const regex = new RegExp(highlightSearchRaw, 'gi');

    text = text.replace(regex, match => {
      countSearch++;
      return `<span class="highlight highlight--search highlight--clickable" data-v-highlight-type="search" data-v-highlight-index-test="${index}">${match}</span>`;
    });
  }

  for (let i in highlightsRaw) {
    let highlight = highlightsRaw[i];
    /* eslint-disable */
    // let regex = new RegExp(
    //   `\\b(?!<span[^>]*>[^<]*)(\\w*${highlight}\\w*[.,;!?:]?)(?![^<]*<\/span>)\\b`,
    //   'g'
    // );
    let regex = new RegExp(
      `\\b(?!<span[^>]*>[^<]*)([\\p{L}\\p{M}]*${highlight}[\\p{L}\\p{M}]*(?:[.,;!?:])?)(?![^<]*<\/span>)\\b`,
      'gu'
    );

    // let regex = new RegExp(
    //   `\\b(?!<span[^>]*>[^<]*)(\\S*${highlight}\\S*)(?![^<]*<\/span>)\\b`,
    //   'g'
    // );
    /* eslint-enable */

    if (regex.test(text)) {
      text = text.replace(regex, (match, offset, string) => {
        indexes.push(index);
        index++;
        if (!counts[highlight]) {
          counts[highlight] = 0;
        }
        counts[highlight]++;

        return `<span class="highlight highlight--clickable" data-v-highlight-type="monitored" data-v-highlight-index-test="${index}">${match}</span>`;
      });
    }
  }

  return {
    text,
    counts,
    indexes,
    countSearch,
  };
};

export const checkOccurrences = array => {
  return Array.isArray(array) && array.length > 0;
};
