var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content",class:{
      'block-scroll': _vm.$store.state.scrollBlock,
      'menu-active': _vm.$store.state.isMenuActive,
    }},[_c('router-view',{style:(`top: ${_vm.baseTop}`)})],1),_vm._v(" "),_c('portal-target',{class:{
      'nav-block-scroll':
        _vm.$store.state.scrollBlock && !_vm.$store.state.isProjectOpen,
      'nav-menu-active': _vm.$store.state.isMenuActive,
    },attrs:{"name":"sticky-nav"}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"menu"}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"modal","multiple":""}}),_vm._v(" "),_c('cookie-notice'),_vm._v(" "),_c('guest-modal'),_vm._v(" "),_c('guest-modal-secondary'),_vm._v(" "),_c('europe-modal'),_vm._v(" "),_c('report-modal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }