<template>
  <div
    class="multi-select"
    :class="{
      'multi-select--open': open,
      'multi-select--has-value': valuesString,
    }"
    v-click-outside="() => toggleOpen(false)"
  >
    <base-button
      ref="toggle"
      theme="gray-5"
      class="multi-select__button"
      @click="toggleOpen(!open)"
      :disabled="$attrs.disabled"
      plain
    >
      <p class="multi-select__values mb-0">
        {{ valuesString }}
        <span class="multi-select__default-value" v-if="!valuesString">
          {{ $attrs.placeholder || 'wybierz z listy...' }}
        </span>
      </p>
    </base-button>

    <div class="multi-select__options">
      <div ref="spacer"></div>
      <input
        v-model="search"
        type="text"
        placeholder="szukaj"
        class="multi-select__options-search"
        autocomplete="false"
      />
      <div class="multi-select__options-inner">
        <base-checkbox
          v-for="(option, index) in filteredOptions"
          :key="index"
          :value="value.includes(option)"
          :name="`${$attrs.name}-${option}`"
          class="multi-select__option"
          @input="handleSelected(option)"
          full
          simple
        >
          {{ option }}
        </base-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      search: '',
    };
  },
  computed: {
    valuesString() {
      return this.value.join(', ');
    },
    filteredOptions() {
      if (this.search === '') {
        return this.options;
      }
      return this.options.filter(option =>
        option.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  mounted() {
    this.updateSpacer();
  },
  watch: {
    value() {
      setTimeout(this.updateSpacer, 0);
    },
  },
  methods: {
    handleSelected(option) {
      if (this.value.includes(option)) {
        this.$emit(
          'input',
          this.value.filter(v => v !== option)
        );
      } else {
        this.$emit('input', this.value.concat([option]));
      }
    },
    toggleOpen(state) {
      this.open = state !== undefined ? state : !this.open;
      if (this.open) {
        this.$emit('focus');
      } else {
        this.$emit('blur');
      }
    },
    updateSpacer() {
      const height = this.$refs.toggle.getHeight();
      this.$refs.spacer.style.height = `${height}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'BaseMultiselectPlain';
</style>
