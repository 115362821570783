import { api } from './api';
const SUPER_SECRET_STORAGE_NAME = 'idxo_v2.14';

export const getApiToken = () =>
  window.localStorage.getItem(SUPER_SECRET_STORAGE_NAME);

export const setApiToken = token => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  window.localStorage.setItem(SUPER_SECRET_STORAGE_NAME, token);
};

export const removeApiToken = () => {
  delete api.defaults.headers.common.Authorization;
  window.localStorage.removeItem(SUPER_SECRET_STORAGE_NAME);
};
