<template>
  <div>
    <div
      class="content"
      :class="{
        'block-scroll': $store.state.scrollBlock,
        'menu-active': $store.state.isMenuActive,
      }"
    >
      <router-view :style="`top: ${baseTop}`" />
    </div>

    <portal-target
      name="sticky-nav"
      :class="{
        'nav-block-scroll':
          $store.state.scrollBlock && !$store.state.isProjectOpen,
        'nav-menu-active': $store.state.isMenuActive,
      }"
    />
    <portal-target name="menu" />
    <portal-target name="modal" multiple />

    <cookie-notice />
    <guest-modal />
    <guest-modal-secondary />
    <europe-modal />
    <report-modal />
  </div>
</template>

<script>
import CookieNotice from './components/CookieNotice/CookieNotice';
import GuestModal from './components/GuestModal/GuestModal';
import GuestModalSecondary from './components/GuestModalSecondary/GuestModalSecondary';
import EuropeModal from '@/components/EuropeModal/EuropeModal.vue';
import ReportModal from '@/components/ReportModal/ReportModal.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    CookieNotice,
    GuestModal,
    GuestModalSecondary,
    EuropeModal,
    ReportModal,
  },
  metaInfo: {
    title: 'Monitoring regulacyjny',
    titleTemplate: '%s | Vigilex',
  },
  computed: {
    ...mapGetters({
      baseTop: 'baseTop',
    }),
  },
  updated() {
    if (!this.$store.state.scrollBlock && this.$route.name !== 'account') {
      document.documentElement.classList.add('block-smooth-scroll');
      window.scrollTo(0, this.$store.state.scrollBlockPosition);
      document.documentElement.classList.remove('block-smooth-scroll');
    }
  },
};
</script>

<style lang="scss">
@import 'styles/_global';

.content {
  position: relative;
  transition: transform 0.15s $easeOutQuad;
  will-change: transform;
}
.block-scroll {
  max-height: 100vh;
  overflow: hidden;
}
.menu-active {
  filter: blur(10px);
  transform: translateX(-10rem);
  transition: transform 0.3s $easeOutQuad;
}
</style>
