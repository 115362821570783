const SUPER_SECRET_STORAGE_NAME = 'idxo_v2.12';

export const setRefreshToken = token => {
  window.localStorage.setItem(SUPER_SECRET_STORAGE_NAME, token);
};

export const getRefreshToken = () =>
  window.localStorage.getItem(SUPER_SECRET_STORAGE_NAME);

export const removeRefreshToken = () =>
  window.localStorage.removeItem(SUPER_SECRET_STORAGE_NAME);
