<template>
  <button
    class="button icon-box"
    :class="{ active, full, 'show-remove-icon': hover && active }"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    @click="disabled ? null : action()"
  >
    <span v-if="full && !nolabel">
      {{ fullLabel }}
    </span>
    <span class="icon">
      <svg class="active-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.8401 4.60999C20.3294 4.099 19.7229 3.69364 19.0555 3.41708C18.388 3.14052 17.6726 2.99817 16.9501 2.99817C16.2276 2.99817 15.5122 3.14052 14.8448 3.41708C14.1773 3.69364 13.5709 4.099 13.0601 4.60999L12.0001 5.66999L10.9401 4.60999C9.90843 3.5783 8.50915 2.9987 7.05012 2.9987C5.59109 2.9987 4.19181 3.5783 3.16012 4.60999C2.12843 5.64169 1.54883 7.04096 1.54883 8.49999C1.54883 9.95903 2.12843 11.3583 3.16012 12.39L4.22012 13.45L12.0001 21.23L19.7801 13.45L20.8401 12.39C21.3511 11.8792 21.7565 11.2728 22.033 10.6053C22.3096 9.93789 22.4519 9.22248 22.4519 8.49999C22.4519 7.77751 22.3096 7.0621 22.033 6.39464C21.7565 5.72718 21.3511 5.12075 20.8401 4.60999Z" fill="#0042FF" stroke="#0042FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <svg class="remove-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4267 4.99628C22.3892 5.03763 22.3497 5.07777 22.3081 5.11655L20.9322 6.40053C20.9969 6.52315 21.0559 6.64891 21.1092 6.77743C21.3355 7.32353 21.4519 7.90887 21.4519 8.49999C21.4519 9.09112 21.3355 9.67645 21.1092 10.2226C20.8829 10.7687 20.5511 11.265 20.133 11.6829L12.0001 19.8158L9.37214 17.1878L7.90903 18.5531L11.293 21.9371C11.6835 22.3276 12.3167 22.3276 12.7072 21.9371L21.5472 13.0971C22.1511 12.4935 22.6301 11.7769 22.9569 10.9881C23.2837 10.1993 23.4519 9.35384 23.4519 8.49999C23.4519 7.64614 23.2837 6.80066 22.9569 6.01185C22.8101 5.65759 22.6325 5.31781 22.4267 4.99628ZM5.07891 15.723L2.45301 13.0971C1.23378 11.8779 0.548828 10.2242 0.548828 8.49999C0.548828 6.77574 1.23378 5.12212 2.45301 3.90289C3.67224 2.68366 5.32587 1.9987 7.05012 1.9987C8.77437 1.9987 10.428 2.68366 11.6472 3.90289L12.0001 4.25578L12.3528 3.90305C12.9564 3.29923 13.6732 2.82006 14.462 2.49324C15.2508 2.1664 16.0963 1.99817 16.9501 1.99817C17.7583 1.99817 18.5589 2.14888 19.3112 2.44214L17.594 4.04448C17.3813 4.01371 17.1661 3.99817 16.9501 3.99817C16.359 3.99817 15.7737 4.11463 15.2276 4.34091C14.6815 4.56719 14.1853 4.89885 13.7674 5.31694L12.7072 6.3771C12.3167 6.76763 11.6835 6.76763 11.293 6.3771L10.233 5.3171C9.38886 4.47295 8.24394 3.9987 7.05012 3.9987C5.8563 3.9987 4.71138 4.47295 3.86723 5.3171C3.02307 6.16126 2.54883 7.30618 2.54883 8.49999C2.54883 9.69381 3.02307 10.8387 3.86723 11.6829L6.54202 14.3577L5.07891 15.723Z" fill="#FF3A00"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.859 1.89086C23.2369 2.29363 23.2167 2.92648 22.8139 3.30436L5.36244 19.6774C4.95967 20.0553 4.32683 20.0351 3.94894 19.6324C3.57106 19.2296 3.59124 18.5968 3.99401 18.2189L21.4455 1.8458C21.8483 1.46791 22.4811 1.48809 22.859 1.89086Z" fill="#FF3A00"/>
      </svg>
      <svg class="unactive-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.8401 4.60999C20.3294 4.099 19.7229 3.69364 19.0555 3.41708C18.388 3.14052 17.6726 2.99817 16.9501 2.99817C16.2276 2.99817 15.5122 3.14052 14.8448 3.41708C14.1773 3.69364 13.5709 4.099 13.0601 4.60999L12.0001 5.66999L10.9401 4.60999C9.90843 3.5783 8.50915 2.9987 7.05012 2.9987C5.59109 2.9987 4.19181 3.5783 3.16012 4.60999C2.12843 5.64169 1.54883 7.04096 1.54883 8.49999C1.54883 9.95903 2.12843 11.3583 3.16012 12.39L4.22012 13.45L12.0001 21.23L19.7801 13.45L20.8401 12.39C21.3511 11.8792 21.7565 11.2728 22.033 10.6053C22.3096 9.93789 22.4519 9.22248 22.4519 8.49999C22.4519 7.77751 22.3096 7.0621 22.033 6.39464C21.7565 5.72718 21.3511 5.12075 20.8401 4.60999Z" stroke="#E9E9E9" :fill="hover && !disabled ? '#E9E9E9' : null" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    full: Boolean,
    active: Boolean,
    nolabel: Boolean,
    itemId: {
      type: Number,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ...mapGetters('list', {
      belovedProjects: 'beloved',
    }),
    // bookmarkedProjects() {
    //   return this.$store.getters['bookmarks/bookmarks'](this.itemType);
    // },
    // active () {
    //   if (this.belovedProjects && this.bookmarkedProjects) {
    //     return this.belovedProjects.includes(this.itemId.toString()) && this.bookmarkedProjects.includes(this.itemId);
    //   }
    //   return false;
    // },
    fullLabel() {
      if (!this.$store.getters['user/isLoggedIn']) {
        return 'Załóż konto i włącz monitoring';
      }
      return this.active ? 'Usuń z ulubionych' : 'Dodaj do ulubionych';
    },
  },
  methods: {
    ...mapActions('list', {
      setBeloved: 'setBeloved',
      unsetBeloved: 'unsetBeloved',
    }),
    mouseEnter() {
      if (this.$util.mq('tablet')) {
        this.hover = true;
      }
    },
    mouseLeave() {
      this.hover = false;
    },
    action () {
      this.active ? this.unsetBeloved({ id: this.itemId }) : this.setBeloved({ id: this.itemId });
      this.mouseLeave();
    }
  },
};
</script>

<style lang="scss" scoped>
.active-icon, .unactive-icon, .remove-icon {
  opacity: 0;
  transition: opacity 0.15s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.active {
  .active-icon {
    opacity: 1;
  }
  .unactive-icon {
    opacity: 0;
  }
}
.show-remove-icon {
  &.active {
    .active-icon {
      opacity: 0;
    }
  }
  .remove-icon {
    opacity: 1;
  }
}
.icon-box {
  &:not(&.active) {
    .unactive-icon {
      opacity: 1;
    }
  }
}
.button {
  padding: 0.5rem;
  text-align: center;

  &:hover {
    .icon-base__path {
      fill: #e9e9e9;
    }
  }
}
.active {
  &:hover {
    .icon-base__path {
      fill: #0042ff;
    }
  }
}
.active.show-remove-icon {
  &:hover {
    .icon-base {
      opacity: 0;
    }
    .icon-remove {
      opacity: 1;
    }
  }
}
.full {
  color: $primary;
  white-space: nowrap;

  .icon {
    margin-left: 0.75rem;
    background-color: white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  }
}

.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 2.71rem;
  height: 2.71rem;
  border-radius: 50%;
  padding-left: 0.1rem;
  padding-top: 0.6rem;
}

.icon-base,
.icon-remove {
  transition: opacity 0.15s;
}

.icon-remove {
  position: absolute;
  top: 0.5rem;
  left: 0.6rem;
  opacity: 0;
}
</style>
