import { store } from '../store/store';
import { OTHER_ACT_NOT_MONITORED, OTHER_ACT_MAP } from '@/util/otherActs';

const NOT_MONITORED = OTHER_ACT_NOT_MONITORED.map(act => OTHER_ACT_MAP[act]);

/* eslint-disable */
export const guardBeforeEach = (to, from, next) => {
  if (store.getters['user/isLoggedIn'] && to.name === 'home') {
    next({
      name: 'news',
      query: to.query,
    });
    return;
  }
  const isRouterLinkClicked = store.getters['isRouterLinkClicked'];
  const isModalActive = store.getters['isModalActive'];
  const isProjectOpen = store.getters['isProjectOpen'];
  const isAccordionOpen = store.getters['isAccordionOpen'];
  const toWhiteList = ['search-legislation', 'login'];
  if (
    (isModalActive || isProjectOpen || isAccordionOpen.length > 0) &&
    !toWhiteList.includes(to.name)
  ) {
    if (isModalActive) {
      store.commit('closeModal');
    } else if (isProjectOpen && isAccordionOpen.length === 0) {
      store.commit('closeProject');
    } else if (isProjectOpen && isAccordionOpen.length > 0) {
      store.commit('setAccordionOpen', []);
    }
    if (!isRouterLinkClicked) {
      process.nextTick(() => {
        store.commit('toggleScroll', true);
        process.nextTick(() => {
          store.commit('toggleScroll', false);
        });
      });
      if (['login'].includes(from.name)) {
        store.state.isModalActive = false;
        store.state.isProjectOpen = false;
        store.state.isAccordionOpen = false;
        next();
      } else {
        next(false);
      }
      return;
    }
  }
  store.commit('setRouterLinkClicked', false);
  if (store.getters['user/isLoggedIn'] && to.name === 'login') {
    next({
      name: 'home',
      query: to.query,
    });
    return;
  }

  if (to.matched.some(record => record.meta.private)) {
    if (!store.getters['user/isLoggedIn']) {
      if (from.name === 'login') {
        next({
          name: 'home',
        });
        return;
      }
      next({
        name: 'login',
        query: { redirect: to.fullPath },
      });
      return;
    }
  }

  store.dispatch('closeMenu');
  store.commit('closeGuestModal');

  if (to.meta.redirect) {
    next({
      name: to.meta.redirect,
      query: to.query,
    });
    return;
  }

  if (to.name === 'news') {
    if (from.meta.relation && !NOT_MONITORED.includes(from.meta.relation)) {
      next({
        name: `news-${from.meta.relation}`,
        query: to.query,
      });
      return;
    }
    next({
      name: 'news-legislation',
      query: to.query,
    });
    return;
  }
  if (to.name === 'my-monitoring') {
    if (from.meta.relation && !NOT_MONITORED.includes(from.meta.relation)) {
      next({
        name: `my-monitoring-${from.meta.relation}`,
        query: to.query,
      });
      return;
    }
    next({
      name: 'my-monitoring-legislation',
      query: to.query,
    });
    return;
  }
  if (to.name === 'search') {
    if (from.meta.relation) {
      next({
        ...to,
        name: `search-${from.meta.relation}`,
        query: to.query,
      });
      return;
    }
    next({
      ...to,
      name: 'search-legislation',
      query: to.query,
    });
    return;
  }

  next();
};
