<template>
  <div
    class="guest-modal-2"
    :class="{ 'guest-modal-2--open': $store.state.reportModalOpen }"
  >
    <div class="guest-modal-2__container">
      <div class="guest-modal-2__top mb-4 mb-tablet-16">
        <base-button-exit
          @click="$store.commit('toggleReportModal', null)"
          class="guest-modal-2__back"
        />
      </div>
      <div class="guest-modal-2__main">
        <div class="row row--vcenter">
          <template v-if="$store.state.reportModalOpen === 'pl'">
            <div class="col-5 tablet-desktop">
              <img
                class="guest-modal-2__image"
                src="./reportmodal.png"
                alt
                width="327"
              />
            </div>
            <div class="col-12 col-tablet-7">
              <h3 class="heading color-primary heading--smaller mb-12">
                Twój okres testowy minął
                <span class="color-primary-darken"
                  ><br />lub nie aktywowałeś jeszcze konta</span
                >
              </h3>

              <ul class="list mb-12">
                <li>
                  Jeśli wciaż nie aktywowałeś konta – znajdź w skrzynce mailowej wiadomość
                  zatytułowaną "Aktywuj konto Vigilex" i wykonaj zawarte w nim instrukcje
                </li>
                <li>
                  Po upływie okresu testowego, korzystanie z modułów tematycznych
                  i wysyłka raportów nie są już dostępne.
                </li>
                <li>
                  W ramach abonamentu możesz korzystać z gotowych modułów tematycznych obejmujących wybrane obszary legislacji.
                </li>
                <li>
                  Możemy też wspólnie stworzyć dla Ciebie nowy moduł tematyczny, dostosowany do Twoich indywidualnych
                  potrzeb. Obejmie on wybrane przez Ciebie projekty z dziedziny, któa Cię interesuje.
                </li>
              </ul>
            </div>
            </template>
            <template v-else-if="$store.state.reportModalOpen === 'eu'">
              <div class="col-5 tablet-desktop">
              <img
                class="guest-modal-2__image"
                src="./reporteumodal.png"
                alt
                width="327"
              />
            </div>
            <div class="col-12 col-tablet-7">
              <h3 class="heading color-primary heading--smaller mb-12">
                Twój okres testowy minął
                <span class="color-primary-darken"
                  ><br />Zamów monitoring legislacji europejskiej</span
                >
              </h3>

              <ul class="list mb-12">
                <li>
                  Po upływie okresu testowego, korzystanie z modułów legislacji europejskiej
                  i wysyłka obejmujących je raportów nie są już dostępne.
                </li>
                 <li>
                  W ramach abonamentu możesz korzystać z gotowych modułów tematycznych obejmujących wybrane obszary legislacji.
                </li>
                <li>
                  Możemy też wspólnie stworzyć dla Ciebie nowy moduł tematyczny, dostosowany do Twoich indywidualnych
                  potrzeb. Obejmie on wybrane przez Ciebie projekty z dziedziny, któa Cię interesuje.
                </li>
              </ul>
            </div>
            </template>
            <template v-else-if="$store.state.reportModalOpen === 'news'">
              <div class="col-5 tablet-desktop">
              <img
                class="guest-modal-2__image"
                src="./reportnewsmodal.png"
                alt
                width="327"
              />
            </div>
            <div class="col-12 col-tablet-7">
              <h3 class="heading color-primary heading--smaller mb-12">
                Twój okres testowy minął
                <span class="color-primary-darken"
                  ><br />Zamów monitoring aktualności, wytycznych lub komunikatów</span
                >
              </h3>

              <ul class="list mb-12">
                <li>
                  Nie możesz uruchomić raportów z aktualności, wytycznych lub komunikatów ze względu na upływ
                  okresu testowego. korzystanie z modułów legislacji europejskiej
                  i wysyłka obejmujących je raportów nie są już dla Ciebie dostępne dostępne.
                </li>
                 <li>
                  W ramach abonamentu możesz otrzymywać raporty z informacjami nt. aktywności organów regulacyjnych,
                   interpelacji, planów posiedzeń komisji sejmowych i zespołów parlamentarnych, żeby lepiej śledzić
                   swoje otoczenie regulacyjne.
                </li>
                <li>
                  Skontaktuj się z nami w celu określenia zakresu informacji, które chcesz otrzymać.
                </li>
              </ul>
            </div>
            </template>

            <div class="mb-4 color-error text-center">
              {{ apiError }}
            </div>

            <base-button
              @click="contactModalOpen = true"
              theme="secondary"
              uppercase
              full
              >Napisz do nas</base-button
            >
            <contact-modal :open="contactModalOpen" @close="closeBothModals" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ContactModal from '../ContactModal';

export default {
  components: {
    ContactModal,
  },
  data() {
    return {
      contactModalOpen: false,
      apiError: '',
    };
  },
  methods: {
    closeBothModals() {
      this.contactModalOpen = false;
      this.$store.commit('toggleReportModal', null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'ReportModal';
</style>
