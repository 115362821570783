var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{ref:"button",tag:"component",staticClass:"base-button",class:{
    [`base-button--${_vm.theme}`]: !_vm.plain,
    [`base-button--${_vm.theme}-plain`]: _vm.plain,
    [`base-button--${_vm.theme}-bordered`]: _vm.bordered,
    'base-button--loading': _vm.loading,
    'base-button--wide': _vm.wide,
    'base-button--bordered': _vm.bordered,
    'base-button--uppercase': _vm.uppercase,
    'base-button--full': _vm.full,
  },attrs:{"to":_vm.to,"type":_vm.$attrs.type || 'button',"disabled":_vm.$attrs.disabled || _vm.loading},on:{"click":_vm.handleClick}},[_c('span',{staticClass:"base-button__text"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }