<template>
  <button
    class="button"
    :class="{ active, full, 'show-remove-icon': showRemoveIcon }"
    @click="action($event)"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <span v-if="full && !nolabel">
      {{ fullLabel }}
    </span>
    <span class="icon">
      <svg
        class="icon-base"
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="24"
      >
        <path
          class="icon-base__path"
          :fill="active ? '#0042ff' : 'none'"
          :stroke="active ? '#0042ff' : '#E9E9E9'"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 1h12v20l-6-5.18L1 21z"
        />
      </svg>
      <svg
        class="icon-remove"
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="24"
        style="margin-left:-1px"
      >
        <g fill="none" fill-rule="evenodd" stroke-linejoin="round">
          <path
            stroke="#FF3A00"
            stroke-width="2"
            d="M6 2.576h12v20l-6-5.18-6 5.18v-8.868z"
          />
          <path
            stroke="#FFF"
            stroke-linecap="round"
            stroke-width="4"
            d="M2 19.267L19.887 2.576"
          />
          <path
            stroke="#FF3A00"
            stroke-linecap="round"
            stroke-width="2"
            d="M2.959 18.373L20.41 2"
          />
        </g>
      </svg>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    full: Boolean,
    active: Boolean,
    nolabel: Boolean,
  },
  data() {
    return {
      showRemoveIcon: this.active,
    };
  },
  computed: {
    fullLabel() {
      if (!this.$store.getters['user/isLoggedIn']) {
        return 'Załóż konto i włącz monitoring';
      }
      return this.active ? 'Wyłącz monitorowanie' : 'Monitoruj zmiany';
    },
  },
  methods: {
    mouseEnter() {
      this.showRemoveIcon = true;
    },
    mouseLeave() {
      this.showRemoveIcon = false;
    },
    action(e) {
      this.$emit('click', e);
      this.mouseLeave();
    }
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding: 0.5rem;
  text-align: center;
  @include mq('tablet') {
    &:hover {
      .icon-base__path {
        fill: #e9e9e9;
      }
    }
  }
}
.active {
  @include mq('tablet') {
    &:hover {
      .icon-base__path {
        fill: #0042ff;
      }
    }
  }

}
.active.show-remove-icon {
  @include mq('tablet') {
    &:hover {
      .icon-base {
        opacity: 0;
      }

      .icon-remove {
        opacity: 1;
      }
    }
  }
}
.full {
  color: $primary;
  white-space: nowrap;

  .icon {
    margin-left: 0.75rem;
    background-color: white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  }
}

.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 2.71rem;
  height: 2.71rem;
  border-radius: 50%;
  padding-left: 0.1rem;
  padding-top: 0.6rem;
}

.icon-base,
.icon-remove {
  transition: opacity 0.15s;
}

.icon-remove {
  position: absolute;
  top: 0.5rem;
  left: 0.6rem;
  opacity: 0;
}
</style>
