<template>
  <button class="more-button" v-on="$listeners">
    <base-spinner v-if="loading" />
    <span v-else class="more-button__inner">pokaż więcej wyników</span>
  </button>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.more-button {
  display: block;
  width: 100%;
  padding: 1.3rem 1rem;
  border-top: 3px solid $gray-5;
  color: $gray-2;
  letter-spacing: 0.02em;

  &:hover {
    .more-button__inner::after {
      border-color: $primary;
    }
  }
}
.more-button__inner {
  &::after {
    @include arrowPseudo($gray-2, 'down');
    display: inline-block;
    margin-bottom: 0.5em;
    margin-left: 1.5rem;
  }
}
</style>
