<template>
  <div
    v-if="!$store.getters['user/isLoggedIn']"
    class="guest-modal"
    :class="{ 'guest-modal--open': $store.state.guestModalOpen }"
  >
    <div class="guest-modal__container">
      <div class="guest-modal__top mb-4 mb-tablet-16">
        <base-button-exit
          @click="$store.commit('closeGuestModal')"
          class="guest-modal__back"
        />
      </div>
      <div class="guest-modal__main">
        <div class="row row--vcenter">
          <div class="col-12 col-tablet-6 col-desktop-5 guest-modal__intro">
            <h3 class="heading color-primary heading--smaller mb-12">
              Załóż konto już teraz!
            </h3>
            <ul class="list">
              <li>
                otrzymasz codzienną porcję informacji o&nbsp;projektach ustaw
                i&nbsp;rozporządzeń z&nbsp;Twojej dziedziny
              </li>
              <li>
                będziesz wiedzieć, jakie istotne zmiany dotkną Twoją branżę,
                organizację lub klienta
              </li>
              <li>
                zyskasz czas na dostosowanie się do zmieniających się przepisów
              </li>
            </ul>
          </div>
          <div class="col-3 guest-modal__image-col desktop">
            <div class="guest-modal__image">
              <phone-component
                v-if="!mobile"
                class="phone__desktop"
                ref="phoneDesktop"
              />
              <!--              <img-->
              <!--                class="guest-modal__image-element"-->
              <!--                src="@/assets/screenshots/phone.png"-->
              <!--                srcset="-->
              <!--                  @/assets/screenshots/phone.png,-->
              <!--                  @/assets/screenshots/phone-2x.png 2x-->
              <!--                "-->
              <!--                alt-->
              <!--                width="476"-->
              <!--              />-->
            </div>
          </div>
          <div class="col-12 col-tablet-6 col-desktop-4 guest-modal__form">
            <p>Uzupełnij dane i&nbsp;zacznij już teraz!</p>
            <form @submit.prevent="signup" class="mb-4">
              <base-input
                name="guest-modal-email"
                type="email"
                v-model="email"
                class="mb-8"
                required
                >adres e-mail</base-input
              >
              <base-button type="submit" theme="secondary" uppercase full
                >Załóż konto</base-button
              >
            </form>
            <base-button
              tag="router-link"
              :to="{ path: '/login', query: { redirect: $route.fullPath } }"
              uppercase
              full
              rel="nofollow"
              >zaloguj się</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneComponent from '@/views/Home/components/PhoneComponent.vue';

export default {
  components: { PhoneComponent },
  data() {
    return {
      email: '',
    };
  },
  methods: {
    signup() {
      this.$store.commit('closeGuestModal');
      this.$router.push({ path: '/signup', query: { email: this.email } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'GuestModal';
</style>
