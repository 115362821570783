<template>
  <div
    v-if="this.$store.getters['user/noUe']"
    class="guest-modal-2"
    :class="{ 'guest-modal-2--open': $store.state.europeModalOpen }"
  >
    <div class="guest-modal-2__container">
      <div class="guest-modal-2__top mb-4 mb-tablet-16">
        <base-button-exit
          @click="$store.commit('toggleEuropeModal', false)"
          class="guest-modal-2__back"
        />
      </div>
      <div class="guest-modal-2__main">
        <div class="row row--vcenter">
          <div class="col-5 tablet-desktop">
            <img
              class="guest-modal-2__image"
              src="./guestmodal.jpg"
              alt
              width="327"
            />
          </div>
          <div class="col-12 col-tablet-7">
            <h3 class="heading color-primary heading--smaller mb-12">
              Śledź legislację europejską.
              <span class="color-primary-darken"
                ><br />Zamów moduł dla swojej specjalizacji</span
              >
            </h3>

            <ul class="list mb-12">
              <li>
                aplikacja może też gromadzić dla Ciebie informacje na temat prac
                legislacyjnych toczących się w instytucjach Unii Europejskiej;
              </li>
              <li>
                Wybierz jeden z gotowych modułów do przetestowania lub stwórzymy
                razem nowy – obejmujący projekty z wybranej dziedziny. Będziesz
                mieć do nich dostęp w wygodnej formie online i otrzymasz raporty
                mailowe o postępach prac legislacyjnych.
              </li>
              <li>
                Zapraszamy do kontaktu – wspólnie ustalimy zakres danych, termin
                i koszt opracowania dodatkowego modułu specjalnie dla Ciebie.
              </li>
            </ul>

            <base-button
              tag="button"
              :theme="apiError ? 'error' : 'primary'"
              full
              uppercase
              @click="enableEU"
              :class="[
                {
                  'mb-2': apiError.length > 0,
                },
              ]"
            >
              Wybierz moduły
            </base-button>
            <div class="mb-4 color-error text-center">
              {{ apiError }}
            </div>

            <base-button
              @click="contactModalOpen = true"
              theme="secondary"
              uppercase
              full
              >Napisz do nas</base-button
            >
            <contact-modal :open="contactModalOpen" @close="closeBothModals" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactModal from '../ContactModal';

export default {
  components: {
    ContactModal,
  },
  data() {
    return {
      contactModalOpen: false,
      apiError: '',
    };
  },
  methods: {
    closeBothModals() {
      this.contactModalOpen = false;
      this.$store.commit('toggleEuropeModal', false);
    },
    async enableEU() {
      this.apiError = '';
      try {
        const response = await this.$store.dispatch('user/grantEUAccess');
        if (response.status === 200) {
          this.$store.commit('setCurrentLegislation', 'eu');
          if (this.$route.name === 'account') {
            this.$router.replace({ hash: 'modules_eu' });
          } else {
            this.$router.push({
              name: 'account',
              hash: 'modules_eu',
            });
          }
          this.closeBothModals();
        }
      } catch (err) {
        const response = err.response;
        if (response.status === 401) {
          this.apiError = 'Musisz być zalogowany';
        } else if (response.status === 403) {
          this.apiError = 'Okres testowy minął';
        } else if (response.status === 500) {
          this.apiError = 'Wystąpił błąd';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'EuropeModal';
</style>
