<template>
  <div
    v-if="this.$store.getters['user/noOtherActs']"
    class="guest-modal-2"
    :class="{ 'guest-modal-2--open': $store.state.guestModalSecondaryOpen }"
  >
    <div class="guest-modal-2__container">
      <div class="guest-modal-2__top mb-4 mb-tablet-16">
        <base-button-exit
          @click="$store.commit('toggleGuestModalSecondary', false)"
          class="guest-modal-2__back"
        />
      </div>
      <div class="guest-modal-2__main">
        <div class="row row--vcenter">
          <div class="col-5 tablet-desktop">
            <img
              class="guest-modal-2__image"
              src="./guestmodal.jpg"
              alt
              width="327"
            />
          </div>
          <div class="col-12 col-tablet-7">
            <h3 class="heading color-primary heading--smaller mb-12">
              Śledź praktykę regulatorów.
              <span class="color-primary-darken"
                ><br>Zamów moduł dla swojej specjalizacji</span
              >
            </h3>

            <ul class="list mb-12">
              <li>
                aplikacja może też gromadzić dla Ciebie wytyczne, komunikaty,
                interpretacje oraz decyzje zamieszczane na stronach wybranych
                regulatorów czy ministerstw;
              </li>
              <li>
                wybierzemy spośród nich te, które sa dla Ciebie istotne, udostępnimy Ci je w wygodnej formie online,
                i&nbsp;poinformujemy Cię mailowo o nowościach.
              </li>
              <li>
                Zapraszamy do kontaktu – wspólnie ustalimy zakres danych, termin i koszt
                opracowania dodatkowego modułu specjalnie dla Ciebie.
              </li>
            </ul>

            <base-button
              @click="contactModalOpen = true"
              theme="secondary"
              uppercase
              full
              >Napisz do nas</base-button
            >
            <contact-modal :open="contactModalOpen" @close="closeBothModals" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactModal from '../ContactModal';

export default {
  components: {
    ContactModal,
  },
  data() {
    return {
      contactModalOpen: false,
    };
  },
  methods: {
    closeBothModals() {
      this.contactModalOpen = false;
      this.$store.commit('toggleGuestModalSecondary', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'GuestModalSecondary';
</style>
