import Vue from 'vue';
import { store } from './store/store';

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    const vm = vnode.context;
    const callback = binding.value;

    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        return callback.call(vm, event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.directive('escape', {
  bind(el, binding, vnode) {
    const vm = vnode.context;
    const callback = binding.value;

    el.escEvent = function(event) {
      if (event.code === 'Escape') {
        store.dispatch('unblockScroll');
        callback.call(vm, event);
      }
    };
    document.body.addEventListener('keydown', el.escEvent);
  },
  unbind(el) {
    document.body.removeEventListener('keydown', el.escEvent);
  },
});
