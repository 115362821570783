<template>
  <base-modal
    :open="visible"
    url="cookies"
    @close="$emit('close')"
    @forceOpen="$emit('forceOpen')"
    class="cookies-modal"
  >
    <h3 class="heading color-primary cookies-modal__heading">
      Zaawansowane ustawienia plików cookie
    </h3>

    <div class="cookies-modal__cookie-list">
      <div
        v-for="(cookie, name) in cookies"
        :key="name"
        class="cookies-modal__cookie"
      >
        <base-switch
          v-model="accepted[name]"
          simple
          :name="name"
          :disabled="cookie.disabled || false"
        >
          <span class="cookies-modal__cookie-title">
            {{ cookie.title }}
          </span>
          <p>{{ cookie.description }}</p>
        </base-switch>
      </div>
    </div>

    <base-button
      @click="saveCookies"
      theme="secondary"
      class="base-button--cookie-modal"
    >
      Zapisz
    </base-button>
  </base-modal>
</template>

<script>
import { defaultCookies, cookies } from '../util/cookies';
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      cookies,
      accepted: {},
    };
  },

  watch: {
    visible(val) {
      if (val) {
        this.accepted = Object.assign(
          defaultCookies(),
          this.$cookies.get('cookies')
        );
      }
    },
  },

  methods: {
    saveCookies() {
      this.$cookies.set('cookies', this.accepted);
      if (this.accepted.analytics) {
        this.$gtag.optIn();
        this.$gtag.pageview(this.$route);
      }
      this.$emit('close');
      this.$emit('hide-notice');
    },
  },
};
</script>

<style lang="scss" scoped>
.cookies-modal__heading {
  font-size: 1.28rem;
  margin-bottom: 1rem;
}

.cookies-modal {
  &__cookie-title {
    font-weight: bold;
  }
  &__cookie {
    margin-bottom: 2rem;
  }
  &__cookie-list {
    margin-top: 3.2rem;
    margin-bottom: 1.8rem;
  }
}
.base-button--cookie-modal {
  width: 100%;
  @include mq('tablet') {
    width: 152px;
  }
}
</style>
