<template>
  <div
    class="multi-select"
    :class="{
      'multi-select--open': open,
      'multi-select--has-value': valuesString,
      'multi-select--with-reset': reset,
      [`multi-select--${theme}`]: true,
    }"
    v-click-outside="() => toggleOpen(false)"
  >
    <p ref="values" class="multi-select__values mb-0">
      {{ valuesString }}
      <base-spinner v-if="loading" class="multi-select__values-spinner" small />
    </p>

    <div class="multi-select__button-container">
      <button
        type="button"
        @click="toggleOpen(!open)"
        :disabled="$attrs.disabled"
        class="multi-select__button"
      >
        <div class="multi-select__button-label">
          <div class="multi-select__button-label-text">
            <slot />
            <span v-if="$attrs.required">*</span>
          </div>
        </div>
      </button>
      <base-button-exit
        v-if="reset"
        @click="$emit('reset')"
        type="gray"
        small
        class="multi-select__reset"
      />
    </div>

    <div class="multi-select__options">
      <div ref="spacer" class="multi-select__options-spacer"></div>
      <div v-if="search" class="multi-select__options-search">
        <input
          :id="$attrs.name"
          v-model="searchTerm"
          type="text"
          placeholder="szukaj"
          class="multi-select__options-search-input"
        />
      </div>

      <div class="multi-select__options-inner">
        <base-checkbox
          v-for="(option, index) in filteredOptions"
          :key="index"
          :value="option.checked"
          :id="`${$attrs.name}-${option.name}`"
          :name="`${$attrs.name}-${option.name}`"
          class="multi-select__option"
          @input="handleSelected(option)"
          full
          simple
          small
        >
          {{ option.name }}
        </base-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      default: 'dark',
    },
    search: Boolean,
    reset: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      open: false,
      searchTerm: '',
    };
  },
  computed: {
    objectOptions() {
      const isObject =
        this.options.length > 0 && typeof this.options[0] === 'object';
      let options;
      if (isObject) {
        options = this.options;
      } else {
        options = this.options.map(o => ({
          name: o,
          value: o,
        }));
      }
      return options.map(option => ({
        ...option,
        checked: this.value.includes(option.value),
      }));
    },
    valuesString() {
      return this.objectOptions
        .filter(o => o.checked)
        .map(o => o.name)
        .join(', ');
    },
    filteredOptions() {
      if (this.searchTerm === '') {
        return this.objectOptions;
      } else {
        return this.objectOptions.filter(option =>
          option.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
    },
  },
  mounted() {
    this.updateSpacer();
  },
  watch: {
    value() {
      setTimeout(this.updateSpacer, 0);
    },
  },
  methods: {
    handleSelected(option) {
      let newValue;
      if (this.value.includes(option.value)) {
        newValue = this.value.filter(v => v !== option.value);
      } else {
        newValue = this.value.concat([option.value]);
      }

      this.$emit('input', newValue);
      this.$emit('toggle-option', option.value);
    },
    toggleOpen(state) {
      this.open = state !== undefined ? state : !this.open;
      if (this.open) {
        this.$emit('focus');
      } else {
        this.$emit('blur');
      }
    },
    updateSpacer() {
      const height = this.$refs.values.offsetHeight;
      this.$refs.spacer.style.height = `${height}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'BaseMultiselect';
</style>
