import { store } from '../store/store';
import { api } from './api';

let isRefreshing = false;
let refreshSubscribers = [];

const subscribedRefresh = originalRequest =>
  new Promise(resolve => {
    refreshSubscribers.push(() => {
      originalRequest.headers.Authorization =
        api.defaults.headers.common.Authorization;
      resolve(api.request(originalRequest));
    });
  });

const callSubscribers = () => {
  refreshSubscribers.forEach(cb => cb());
};

export const intercept401 = async originalRequest => {
  const request = subscribedRefresh(originalRequest);
  if (isRefreshing) {
    return request;
  }
  try {
    isRefreshing = true;
    await store.dispatch('user/refreshToken');
    callSubscribers();
    isRefreshing = false;
  } catch (error) {
    isRefreshing = false;
    return Promise.reject(error);
  }
  refreshSubscribers = [];
  return request;
};
