var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guest-modal-2",class:{ 'guest-modal-2--open': _vm.$store.state.reportModalOpen }},[_c('div',{staticClass:"guest-modal-2__container"},[_c('div',{staticClass:"guest-modal-2__top mb-4 mb-tablet-16"},[_c('base-button-exit',{staticClass:"guest-modal-2__back",on:{"click":function($event){return _vm.$store.commit('toggleReportModal', null)}}})],1),_vm._v(" "),_c('div',{staticClass:"guest-modal-2__main"},[_c('div',{staticClass:"row row--vcenter"},[(_vm.$store.state.reportModalOpen === 'pl')?[_vm._m(0),_vm._v(" "),_vm._m(1)]:(_vm.$store.state.reportModalOpen === 'eu')?[_vm._m(2),_vm._v(" "),_vm._m(3)]:(_vm.$store.state.reportModalOpen === 'news')?[_vm._m(4),_vm._v(" "),_vm._m(5)]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"mb-4 color-error text-center"},[_vm._v("\n            "+_vm._s(_vm.apiError)+"\n          ")]),_vm._v(" "),_c('base-button',{attrs:{"theme":"secondary","uppercase":"","full":""},on:{"click":function($event){_vm.contactModalOpen = true}}},[_vm._v("Napisz do nas")]),_vm._v(" "),_c('contact-modal',{attrs:{"open":_vm.contactModalOpen},on:{"close":_vm.closeBothModals}})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5 tablet-desktop"},[_c('img',{staticClass:"guest-modal-2__image",attrs:{"src":require("./reportmodal.png"),"alt":"","width":"327"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-tablet-7"},[_c('h3',{staticClass:"heading color-primary heading--smaller mb-12"},[_vm._v("\n              Twój okres testowy minął\n              "),_c('span',{staticClass:"color-primary-darken"},[_c('br'),_vm._v("lub nie aktywowałeś jeszcze konta")])]),_vm._v(" "),_c('ul',{staticClass:"list mb-12"},[_c('li',[_vm._v("\n                Jeśli wciaż nie aktywowałeś konta – znajdź w skrzynce mailowej wiadomość\n                zatytułowaną \"Aktywuj konto Vigilex\" i wykonaj zawarte w nim instrukcje\n              ")]),_vm._v(" "),_c('li',[_vm._v("\n                Po upływie okresu testowego, korzystanie z modułów tematycznych\n                i wysyłka raportów nie są już dostępne.\n              ")]),_vm._v(" "),_c('li',[_vm._v("\n                W ramach abonamentu możesz korzystać z gotowych modułów tematycznych obejmujących wybrane obszary legislacji.\n              ")]),_vm._v(" "),_c('li',[_vm._v("\n                Możemy też wspólnie stworzyć dla Ciebie nowy moduł tematyczny, dostosowany do Twoich indywidualnych\n                potrzeb. Obejmie on wybrane przez Ciebie projekty z dziedziny, któa Cię interesuje.\n              ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5 tablet-desktop"},[_c('img',{staticClass:"guest-modal-2__image",attrs:{"src":require("./reporteumodal.png"),"alt":"","width":"327"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-tablet-7"},[_c('h3',{staticClass:"heading color-primary heading--smaller mb-12"},[_vm._v("\n              Twój okres testowy minął\n              "),_c('span',{staticClass:"color-primary-darken"},[_c('br'),_vm._v("Zamów monitoring legislacji europejskiej")])]),_vm._v(" "),_c('ul',{staticClass:"list mb-12"},[_c('li',[_vm._v("\n                Po upływie okresu testowego, korzystanie z modułów legislacji europejskiej\n                i wysyłka obejmujących je raportów nie są już dostępne.\n              ")]),_vm._v(" "),_c('li',[_vm._v("\n                W ramach abonamentu możesz korzystać z gotowych modułów tematycznych obejmujących wybrane obszary legislacji.\n              ")]),_vm._v(" "),_c('li',[_vm._v("\n                Możemy też wspólnie stworzyć dla Ciebie nowy moduł tematyczny, dostosowany do Twoich indywidualnych\n                potrzeb. Obejmie on wybrane przez Ciebie projekty z dziedziny, któa Cię interesuje.\n              ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5 tablet-desktop"},[_c('img',{staticClass:"guest-modal-2__image",attrs:{"src":require("./reportnewsmodal.png"),"alt":"","width":"327"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-tablet-7"},[_c('h3',{staticClass:"heading color-primary heading--smaller mb-12"},[_vm._v("\n              Twój okres testowy minął\n              "),_c('span',{staticClass:"color-primary-darken"},[_c('br'),_vm._v("Zamów monitoring aktualności, wytycznych lub komunikatów")])]),_vm._v(" "),_c('ul',{staticClass:"list mb-12"},[_c('li',[_vm._v("\n                Nie możesz uruchomić raportów z aktualności, wytycznych lub komunikatów ze względu na upływ\n                okresu testowego. korzystanie z modułów legislacji europejskiej\n                i wysyłka obejmujących je raportów nie są już dla Ciebie dostępne dostępne.\n              ")]),_vm._v(" "),_c('li',[_vm._v("\n                W ramach abonamentu możesz otrzymywać raporty z informacjami nt. aktywności organów regulacyjnych,\n                 interpelacji, planów posiedzeń komisji sejmowych i zespołów parlamentarnych, żeby lepiej śledzić\n                 swoje otoczenie regulacyjne.\n              ")]),_vm._v(" "),_c('li',[_vm._v("\n                Skontaktuj się z nami w celu określenia zakresu informacji, które chcesz otrzymać.\n              ")])])])
}]

export { render, staticRenderFns }