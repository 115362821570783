<template>
  <div
    class="field"
    :class="{
      'field--light': theme === 'light',
      'field--light-blue': theme === 'light-blue',
      'field--white': theme === 'white',
      'field--error': error,
      'field--has-value': hasValue,
      'field--icon': $slots.icon,
      'field--no-label': !$slots.default,
    }"
  >
    <input
      class="input"
      :id="$attrs.name"
      :type="renderedType"
      v-bind="$attrs"
      v-on="inputListeners"
      ref="input"
      @keyup.enter="e => $emit('enter', e)"
    />
    <div v-if="$slots.icon" class="input-icon">
      <slot name="icon" />
    </div>
    <button
      v-if="type === 'password'"
      type="button"
      @click="showPassword = !showPassword"
      class="show-password"
      :class="{ 'password-visible': showPassword }"
    >
      <svg
        class="show-password__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 14"
      >
        <title>Show password</title>
        <g class="show-password__off">
          <path
            d="M11 0C5.5 0 0 7 0 7s5.5 7 11 7 11-7 11-7-5.5-7-11-7zm6.3 9c-2.2 1.9-4.4 3-6.3 3-1.8 0-4-1.1-6.2-3-.8-.6-1.5-1.3-2.1-2 .6-.7 1.3-1.4 2-2C7 3.1 9.2 2 11 2c1.8 0 4 1.1 6.3 3 .7.6 1.4 1.3 2 2-.6.7-1.3 1.4-2 2z"
          />
          <circle cx="11" cy="7" r="3" />
        </g>
        <path
          class="show-password__on"
          d="M11 0C5.5 0 0 7 0 7s5.5 7 11 7 11-7 11-7-5.5-7-11-7zM8 7c0-1.7 1.3-3 3-3s3 1.3 3 3-1.3 3-3 3-3-1.3-3-3z"
        />
      </svg>
    </button>
    <div class="label__container">
      <label v-if="$slots.default" class="label" :for="$attrs.name">
        <span class="label__text">
          <slot />
          <span v-if="$attrs.required">*</span>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'text',
    },
    error: Boolean,
    theme: {
      type: String,
      default: 'dark',
      validator: function(value) {
        return ['white', 'light', 'light-blue', 'dark'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    hasValue() {
      return this.$attrs.value !== '';
    },
    renderedType() {
      if (this.type === 'password' && this.showPassword) {
        return 'text';
      }
      return this.type;
    },
    inputListeners() {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit('input', event.target.value);
        },
      };
    },
  },
  mounted() {
    if (this.$attrs.autofocus) {
      this.$refs.input.focus();
    }
  },
};
</script>

<style lang="scss" scoped>
@import './BaseInput';
</style>
