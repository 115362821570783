import { setup } from 'axios-cache-adapter';

import { apiUrl } from './apiUrl';
import { intercept401 } from './intercept401';

export const api = setup({
  cache: {
    maxAge: 15 * 60 * 1000,
  },
  baseURL: apiUrl,
});

const urlsExemptFrom401 = ['/auth/jwt/refresh', '/auth/jwt/create', 'v0/grant_eu_access'];
api.interceptors.response.use(undefined, error => {
  if (
    error.response &&
    error.response.status === 401 &&
    !urlsExemptFrom401.includes(error.config.url)
  ) {
    return intercept401(error.config);
  }
  return Promise.reject(error);
});
