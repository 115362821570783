<template>
  <button
    type="button"
    class="button"
    :class="{ [type]: true, small }"
    v-on="$listeners"
  >
    <span class="visuallyhidden">Close</span>
    <span class="icon"></span>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'dark',
    },
    small: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@mixin color($color) {
  .icon {
    &::before,
    &::after {
      background-color: $color;
    }
  }
  &:hover {
    .icon {
      &::before,
      &::after {
        background-color: $error;
      }
    }
  }
}
.button {
  padding: 0.5rem;
  @include color($gray-2);
}
.light {
  @include color(#fff);
}
.gray {
  @include color($gray-3);
}

.icon {
  position: relative;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 0.15s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -1px;
    height: 3px;
    border-radius: 2px;
    transition: background-color 0.15s;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
.small .icon {
  width: 0.85rem;
  height: 0.85rem;

  &::before,
  &::after {
    height: 2px;
  }
}
</style>
