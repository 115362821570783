export const scrollBehavior = function(to, from, savedPosition) {
  if (to.params.noScroll) {
    return;
  }

  if (savedPosition) {
    return savedPosition;
  }

  // hash scrolling
  if (to.hash) {
    const offset = to.name === 'account' ? 120 : 30;
    return { selector: to.hash, offset: { y: offset } };
  }

  return new Promise(resolve => {
    this.app.$root.$once('triggerScroll', () => {
      resolve({ x: 0, y: 0 });
    });
  });
};
