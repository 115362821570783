<template>
  <div
    class="field"
    :class="{
      'field--light': theme === 'light',
      'field--simple': simple,
      'field--full': full,
      'field--small': small,
    }"
  >
    <input
      class="input"
      type="checkbox"
      :name="name"
      :id="name"
      :checked="value"
      v-bind="$attrs"
      @input="$emit('input', $event.target.checked)"
    />
    <label
      class="label"
      :for="name"
      :class="{
        'show-remove-icon': showRemoveIcon,
        'show-add-icon': showAddIcon,
      }"
      @mouseleave="mouseLeave"
    >
      <slot />
      <span class="label__icon"></span>
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    name: String,
    value: Boolean,
    simple: Boolean,
    small: Boolean,
    full: Boolean,
    theme: {
      type: String,
      default: 'dark',
      validator: function(value) {
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      showAddIcon: !this.value,
      showRemoveIcon: this.value,
    };
  },
  methods: {
    mouseLeave() {
      this.showAddIcon = !this.value;
      this.showRemoveIcon = this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './BaseCheckbox';
</style>
