<template>
  <div>
    <div class="cookie-notice" :class="{ active }">
      <div class="cookie-notice__wrap">
        <p class="cookie-notice__paragraph mb-8 mb-desktop-0">
          W celu prawidłowego działania serwisu oraz analizy ilości odwiedzin
          wykorzystujemy pliki cookies. Po kliknięciu w przycisk "szczegóły"
          dowiesz się więcej o plikach cookies, które wykorzystujemy i otworzysz
          menu, które umożliwia wyrażenie zgód na wybrane pliki cookie.
        </p>

        <div class="cookie-notice__buttons">
          <base-button @click="denyCookies" theme="gray-2" plain>
            Odrzuć wszystkie
          </base-button>

          <div class="cookie-notice__accept-wrap">
            <base-button @click="acceptCookies" theme="secondary">
              Akceptuję
            </base-button>

            <button @click="modalOpen = true" class="cookie-notice__link-more">
              Szczegóły
            </button>
          </div>
        </div>
      </div>
    </div>
    <cookies-modal
      :visible="modalOpen"
      @close="modalOpen = false"
      @forceOpen="modalOpen = true"
      @hide-notice="active = false"
    />
  </div>
</template>

<script>
import CookiesModal from '../CookiesModal';
import { allAccepted, onlyRequired } from '../../util/cookies';
export default {
  components: {
    CookiesModal,
  },
  data() {
    return {
      active: false,
      modalOpen: false,
    };
  },
  created() {
    if (!this.$cookies.isKey('cookies')) {
      this.active = true;
    }
  },
  methods: {
    acceptCookies() {
      this.$cookies.set('cookies', allAccepted);
      this.$gtag.optIn();
      this.$gtag.pageview(this.$route);
      this.active = false;
    },
    denyCookies() {
      this.$cookies.set('cookies', onlyRequired);
      this.active = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'CookieNotice';
</style>
