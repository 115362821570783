<template>
  <base-modal
    :open="open"
    url="contact"
    @close="$emit('close')"
    @forceOpen="$emit('forceOpen')"
    theme="mobileCloseX"
  >
    <div class="contact-modal">
      <div v-if="!sentMail" class="mb-16">
        <h3 class="heading--medium-small color-primary">Napisz do nas</h3>
        <form @submit.prevent="sendMail" class="contact-form">
          <div class="row">
            <div class="col-12 col-tablet-5">
              <base-input v-model="name" name="contact-name" class="mb-4"
                >Imię i nazwisko</base-input
              >
              <base-input
                v-model="email"
                name="contact-email"
                class="mb-4"
                :required="true"
                >Adres e-mail</base-input
              >
            </div>
            <div class="col-12 col-tablet-7">
              <base-textarea
                v-model="message"
                name="contact-message"
                placeholder="Treść wiadomości *"
                class="contact-form__message mb-4"
                :required="true"
              />
            </div>
            <div class="col-12 col-tablet-5">
              <base-button
                type="submit"
                theme="secondary"
                :loading="sendingMail"
                uppercase
                full
                >Wyślij</base-button
              >
            </div>
          </div>
        </form>
      </div>
      <div v-else class="mb-16">
        <h3 class="heading--medium-small color-primary">
          Dziękujemy za wysłanie wiadomości.
        </h3>
        <p class="heading--medium-small mb-12">
          Skontaktujemy się z Państwem jak tylko będzie to możliwe.
        </p>
        <base-button @click="closeModal" theme="secondary" plain uppercase full
          >Zamknij</base-button
        >
      </div>

      <contact-legal />
    </div>
  </base-modal>
</template>

<script>
import ContactLegal from './ContactLegal';

export default {
  props: {
    open: Boolean,
  },
  components: {
    ContactLegal,
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      sendingMail: false,
      sentMail: false,
    };
  },
  methods: {
    closeModal() {
      this.sentMail = false;
      this.$emit('close');
    },
    async sendMail() {
      this.sendingMail = true;
      await this.$api.post('v1/send_message/', {
        email: this.email,
        content: this.message,
      });
      this.name = '';
      this.email = '';
      this.message = '';
      this.sendingMail = false;
      this.sentMail = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-modal {
  @include mq('tablet') {
    padding: 0 1rem;
  }
  @include mq('desktop') {
    padding: 2rem 5rem;
  }
}
.contact-form {
  font-size: 1.14rem;
}
.contact-form__message {
  padding: 1.1rem 1.2rem;
  min-height: 8rem;
}
</style>
