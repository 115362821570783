<template>
  <div
    class="tooltip"
    :class="
      `${type} ${position} ${disabled ? 'disabled' : ''} ${
        positionTablet ? `tablet-${positionTablet}` : ''
      }`
    "
  >
    <div class="text" :class="{ wider, nowrap, small, narrow, textNormal }">
      <slot name="text" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    position: {
      type: String,
      default: 'top',
    },
    positionTablet: {
      type: String,
      default: '',
    },
    wider: Boolean,
    nowrap: Boolean,
    small: Boolean,
    narrow: Boolean,
    textNormal: Boolean,
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  z-index: 100;
  position: relative;
  display: inline-block;

  &:not(.disabled):hover {
    .text {
      opacity: 1;
      pointer-events: auto;
      transform: translate(0);
    }
  }
}
.text {
  position: absolute;
  padding: 1.2rem 1.5rem;
  min-width: 18rem;
  border-radius: 0.2rem;
  font-weight: normal;
  font-size: 0.85rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s, transform 0.2s $easeOutCubic;

  &.wider {
    @include mq('tablet') {
      min-width: 21rem;
    }
  }
  &.nowrap {
    min-width: 0;
    white-space: nowrap;
  }
  &.narrow {
    min-width: 12rem;
    padding: 0.8rem 1.5rem;
  }
  &.textNormal {
    font-size: 1rem;
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
  & p:last-child {
    margin-bottom: 0;
  }
}
.primary .text {
  color: white;
  background-color: $primary;
}
.secondary .text {
  color: $gray-1;
  background-color: $secondary;
}

@mixin top() {
  .text {
    top: auto;
    bottom: 100%;
    left: -2rem;
    right: auto;
    margin: auto;
    margin-bottom: 1rem;
    transform: translateY(0.6rem);

    &.small {
      top: auto;

      &::before {
        left: 2rem;
        top: 99%;
      }
    }

    &::before {
      top: 99%;
      left: 2rem;
      right: auto;
      border: 0.8rem solid transparent;
      border-top-width: 0.6rem;
      border-bottom: none;
    }
  }
  &.primary .text::before {
    border-color: $primary transparent transparent transparent;
  }
  &.secondary .text::before {
    border-color: $secondary transparent transparent transparent;
  }
}
@mixin top-center() {
  .text {
    top: auto;
    bottom: 100%;
    left: 50%;
    right: auto;
    margin: auto;
    margin-bottom: 1rem;
    transform: translateY(0.6rem) translateX(-50%);

    &.small {
      top: auto;

      &::before {
        left: 50%;
        top: 99%;
      }
    }

    &::before {
      top: 99%;
      left: 50%;
      right: auto;
      margin-left: -0.8rem;
      border: 0.8rem solid transparent;
      border-top-width: 0.6rem;
      border-bottom: none;
    }
  }
  &.primary .text::before {
    border-color: $primary transparent transparent transparent;
  }
  &.secondary .text::before {
    border-color: $secondary transparent transparent transparent;
  }
}
@mixin left() {
  .text {
    right: 100%;
    left: auto;
    top: -3rem;
    bottom: auto;
    margin: auto;
    margin-right: 1rem;
    transform: translateX(-0.6rem);

    &.small {
      top: -0.5rem;

      &::before {
        left: 97%;
        top: 0.35rem;
      }
    }

    &::before {
      left: 99%;
      right: auto;
      top: 2.8rem;
      border: 0.8rem solid transparent;
      border-left-width: 0.6rem;
      border-right: none;
    }
  }
  &.primary .text::before {
    border-color: transparent transparent transparent $primary;
  }
  &.secondary .text::before {
    border-color: transparent transparent transparent $secondary;
  }
}
@mixin right() {
  .text {
    left: 100%;
    right: auto;
    top: -3rem;
    bottom: auto;
    margin: auto;
    margin-left: 1rem;
    transform: translateX(-0.6rem);

    &.small {
      top: -0.5rem;

      &::before {
        left: 97%;
        top: 0.35rem;
      }
    }

    &::before {
      left: auto;
      right: 99%;
      top: 2.8rem;
      border: 0.8rem solid transparent;
      border-right-width: 0.6rem;
      border-left: none;
    }
  }
  &.primary .text::before {
    border-color: transparent $primary transparent transparent;
  }
  &.secondary .text::before {
    border-color: transparent $secondary transparent transparent;
  }
}
.top {
  @include top();
}
.top-center {
  @include top-center();
  &:not(.disabled):hover {
    .text {
      transform: translateY(0) translateX(-50%);
    }
  }
}
.left {
  @include left();
}
.right {
  @include right();
}
.tablet-top {
  @include mq('tablet') {
    @include top();
  }
}
.tablet-left {
  @include mq('tablet') {
    @include left();
  }
}
.tablet-right {
  @include mq('tablet') {
    @include right();
  }
}
</style>
