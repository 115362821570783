var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field",class:{
    'field--light': _vm.theme === 'light',
    'field--simple': _vm.simple,
    'field--full': _vm.full,
    'field--small': _vm.small,
  }},[_c('input',_vm._b({staticClass:"input",attrs:{"type":"checkbox","name":_vm.name,"id":_vm.name},domProps:{"checked":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.checked)}}},'input',_vm.$attrs,false)),_vm._v(" "),_c('label',{staticClass:"label",attrs:{"for":_vm.name}},[_c('span',{staticClass:"label__switch",class:{
        'label__switch--active': _vm.value,
        'label__switch--disabled': _vm.$attrs.disabled,
      }}),_vm._v(" "),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }