<template>
  <div
    class="field"
    :class="{
      'field--light': theme === 'light',
      'field--simple': simple,
      'field--full': full,
      'field--small': small,
    }"
  >
    <!-- <input
      class="input"
      type="checkbox"
      :name="name"
      :id="name"
      :checked="value"
      v-bind="$attrs"
      @input="$emit('input', $event.target.checked)"
    /> -->

    <!-- <div> -->
    <!-- :aria-checked="value.toString()" -->
    <!-- <span
        class="toggle-wrapper"
        role="checkbox"
        tabindex="0"
        @click="toggle"
        @keydown.space.prevent="toggle"
      >
        <span class="toggle-background" :class="backgroundStyles" />
        <span class="toggle-indicator" :style="indicatorStyles" />
      </span> -->
    <!-- </div> -->

    <input
      class="input"
      type="checkbox"
      :name="name"
      :id="name"
      :checked="value"
      v-bind="$attrs"
      @input="$emit('input', $event.target.checked)"
    />

    <label :for="name" class="label">
      <span
        class="label__switch"
        :class="{
          'label__switch--active': value,
          'label__switch--disabled': $attrs.disabled,
        }"
      ></span>
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    name: String,
    value: Boolean,
    simple: Boolean,
    small: Boolean,
    full: Boolean,
    theme: {
      type: String,
      default: 'dark',
      validator: function(value) {
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    // indicatorClass() {
    //   return {
    //     {
    //     }
    //   }
    // }
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './BaseSwitch';
</style>
