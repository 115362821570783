import Vue from 'vue';
import Vuex from 'vuex';

import { user } from './user';
import { bookmarks } from './bookmarks';
import { list } from '@/store/list';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    user,
    bookmarks,
    list,
  },
  state: {
    isSearchActive: false,
    isRouterLinkClicked: false,
    isMenuActive: false,
    isProjectOpen: false,
    isAccordionOpen: [],
    isModalActive: false,
    scrollBlock: false,
    scrollBlockPosition: 0,
    guestModalOpen: false,
    guestModalSecondaryOpen: false,
    europeModalOpen: false,
    advancedSearchOpen: true,
    reportModalOpen: null,
    currentLegislation: 'pl',
  },
  getters: {
    baseTop: state =>
      state.scrollBlock ? -state.scrollBlockPosition + 'px' : 'auto',
    isRouterLinkClicked: state => state.isRouterLinkClicked,
    isModalActive: state => state.isModalActive,
    isProjectOpen: state => state.isProjectOpen,
    isAccordionOpen: state => state.isAccordionOpen,
  },
  mutations: {
    setRouterLinkClicked(state, payload) {
      state.isRouterLinkClicked = payload;
    },
    setScrollBlockPosition(state, payload) {
      if (payload > 0) {
        state.scrollBlockPosition = payload;
      }
    },
    toggleMenu(state, payload) {
      state.isMenuActive = payload;
    },
    toggleScroll(state, payload) {
      state.scrollBlock = payload;
    },
    openModal(state) {
      if (state.isProjectOpen) {
        window.removeEventListener('scroll', openProjectScrollListener);
      }
      state.isModalActive = true;
      // state.scrollBlockPosition = window.scrollY;
    },
    closeModal(state) {
      if (state.isProjectOpen) {
        window.addEventListener('scroll', openProjectScrollListener);
      }
      state.isModalActive = false;
    },
    openProject(state) {
      state.isProjectOpen = true;
      this.commit('setScrollBlockPosition', window.scrollY);
      window.addEventListener('scroll', openProjectScrollListener);
    },
    closeProject(state) {
      state.isProjectOpen = false;
      state.isAccordionOpen = [];
      window.removeEventListener('scroll', openProjectScrollListener);
    },
    openGuestModal(state) {
      state.guestModalOpen = true;
    },
    closeGuestModal(state) {
      state.guestModalOpen = false;
    },
    toggleGuestModalSecondary(state, payload) {
      state.guestModalSecondaryOpen = payload;
    },
    toggleEuropeModal(state, payload) {
      state.europeModalOpen = payload;
    },
    toggleReportModal(state, payload) {
      state.reportModalOpen = payload;
    },
    setAdvancedSearchOpen(state, payload) {
      state.advancedSearchOpen = payload;
    },
    setCurrentLegislation(state, payload) {
      state.currentLegislation = payload;
    },
    setAccordionOpen(state, payload) {
      state.isAccordionOpen = payload;
    },
  },
  actions: {
    openMenu({ commit, dispatch }) {
      commit('toggleMenu', true);
      dispatch('blockScroll');
    },
    closeMenu({ commit, dispatch }) {
      commit('toggleMenu', false);
      dispatch('unblockScroll');
    },
    blockScroll({ commit }) {
      commit('toggleScroll', true);
      commit('setScrollBlockPosition', window.scrollY);
    },
    unblockScroll({ commit }) {
      commit('toggleScroll', false);
    },
  },
});

function openProjectScrollListener() {
  if (!store.state.isModalActive) {
    store.commit('setScrollBlockPosition', window.scrollY);
  }
}
