<template>
  <div class="field" :class="{ 'field--full': full }">
    <label :for="name" class="label"><slot /></label>
    <select
      :name="name"
      :id="name"
      class="select"
      :required="required"
      v-model="selectedValue"
      @input="$emit('input', $event.target.value)"
      :class="{ 'select--dim': selectedValue === 'defaultValue' }"
    >
      <option value="defaultValue" disabled selected>wybierz z listy</option>
      <option
        v-for="option in options"
        :value="option.value"
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    options: Array,
    required: Boolean,
    full: Boolean,
  },
  data() {
    return {
      selectedValue: 'defaultValue',
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import 'BaseSelect';
</style>
