import { api } from '@/api/api';
import { store } from '@/store/store';
import Vue from 'vue';

export const list = {
  namespaced: true,
  state: {
    modules: [],
    activeModules: [],
    pksByModule: {},
    itemsByModule: [],
    favoriteHated: null,
    api: null,
  },
  getters: {
    modules: state => state.modules,
    activeModules: state => state.activeModules,
    pksByModule: state => state.pksByModule,
    favoriteHated: state => state.favoriteHated,
    beloved: state => (state.favoriteHated ? state.favoriteHated.beloved : []),
    hated: state => (state.favoriteHated ? state.favoriteHated.hateful : []),
    favorite: state =>
      state.favoriteHated ? state.favoriteHated.favorite : [],
    api: state => state.api,
  },
  mutations: {
    clearListState(state) {
      state.modules = [];
      state.activeModules = [];
      state.pksByModule = {};
      state.favoriteHated = null;
      // state.api = null;
    },
    clearExcludedFromModule(state, id) {
      if (state.favoriteHated.excluded_from_module[id]) {
        Vue.set(state.favoriteHated.excluded_from_module, id, []);
      }
    },
    setModules(state, payload) {
      state.modules = payload;
    },
    setActiveModules(state, payload) {
      state.activeModules = payload;
    },
    setPksByModule(state, payload) {
      state.pksByModule = payload;
    },
    setItemsByModule(state, payload) {
      state.itemsByModule = payload;
    },
    setFavoriteHated(state, payload) {
      state.favoriteHated = payload;
    },
    setApi(state, payload) {
      state.api = payload;
    },
    addBelovedItem(state, id) {
      state.favoriteHated.beloved.push(id.toString());
    },
    removeBelovedItem(state, id) {
      const index = state.favoriteHated.beloved.indexOf(id.toString());
      if (index > -1) {
        state.favoriteHated.beloved.splice(index, 1);
      }
    },
    includeProjectToModule(state, { moduleId, projectId }) {
      const index = state.favoriteHated.excluded_from_module[projectId].indexOf(
        moduleId
      );
      if (index >= 0) {
        state.favoriteHated.excluded_from_module[projectId].splice(index, 1);
      }
    },
    excludeProjectFromModule(state, { moduleId, projectId }) {
      let modulesExcluded = state.favoriteHated.excluded_from_module;
      if (!modulesExcluded[projectId]) {
        Vue.set(modulesExcluded, projectId, [moduleId]);
      } else {
        modulesExcluded[projectId].push(moduleId);
      }
    },
  },
  actions: {
    async setModulesForCurrentList({ commit, state }) {
      const enabledModules =
        state.api && state.api.modulesWithName
          ? store.state.user[state.api.modulesWithName]
          : store.state.user.modules.filter(module =>
            store.state.user[state.api.listModules].includes(
              module[state.api.moduleId]
            )
          );
      commit(
        'setModules',
        enabledModules.map(module => {
          return {
            name: module.name_to_display ? module.name_to_display : module.name,
            id: module[state.api.moduleId],
          };
        })
      );
      const activeModuleNames = window.localStorage.getItem(
        'active-monitoring-modules'
      );
      const activeModules = enabledModules.filter(module =>
        activeModuleNames.includes(
          module.name ? module.name : module.name_to_display
        )
      );
      commit('setActiveModules', activeModules);
      const requests = enabledModules.map(module =>
        api.get(`v1/${state.api.modules}/${module[state.api.moduleId]}/`)
      );
      const modulesData = await Promise.all(requests);
      let pksByModule = {};
      let itemsByModule = {};
      for (let i = 0; i < modulesData.length; i++) {
        pksByModule = Object.assign(pksByModule, {
          [enabledModules[i][state.api.moduleId]]:
            modulesData[i].data.get_cached_only_pk_list,
        });
        itemsByModule = Object.assign(itemsByModule, {
          [enabledModules[i][state.api.moduleId]]:
          modulesData[i].data.generate_cached_only_queryset,
        });
      }
      commit('setPksByModule', pksByModule);
      commit('setItemsByModule', itemsByModule);
    },
    async getFavoriteHated({ commit, state, dispatch }) {
      const { data } = await api.get(`v1/${state.api.favoriteHated}/`, {
        cache: {
          ignoreCache: true,
        },
      });
      commit(
        'setFavoriteHated',
        data.results[0][state.api.favoriteHatedResponse]
      );
      dispatch(state.api.bookmarks, null, { root: true });
    },
    async setBeloved({ dispatch, commit, state }, { id }) {
      await api.put(`v0/${state.api.list}/toggle_beloved/${id}`, {
        cache: {
          ignoreCache: true,
        },
      });
      commit('addBelovedItem', id);
    },
    async unsetBeloved({ dispatch, commit, state }, { id }) {
      await api.delete(`v0/${state.api.list}/toggle_beloved/${id}`, {
        cache: {
          ignoreCache: true,
        },
      });
      commit('removeBelovedItem', id);
    },
  },
};
