var render = function render(){var _vm=this,_c=_vm._self._c;return (this.$store.getters['user/noUe'])?_c('div',{staticClass:"guest-modal-2",class:{ 'guest-modal-2--open': _vm.$store.state.europeModalOpen }},[_c('div',{staticClass:"guest-modal-2__container"},[_c('div',{staticClass:"guest-modal-2__top mb-4 mb-tablet-16"},[_c('base-button-exit',{staticClass:"guest-modal-2__back",on:{"click":function($event){return _vm.$store.commit('toggleEuropeModal', false)}}})],1),_vm._v(" "),_c('div',{staticClass:"guest-modal-2__main"},[_c('div',{staticClass:"row row--vcenter"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-12 col-tablet-7"},[_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('base-button',{class:[
              {
                'mb-2': _vm.apiError.length > 0,
              },
            ],attrs:{"tag":"button","theme":_vm.apiError ? 'error' : 'primary',"full":"","uppercase":""},on:{"click":_vm.enableEU}},[_vm._v("\n            Wybierz moduły\n          ")]),_vm._v(" "),_c('div',{staticClass:"mb-4 color-error text-center"},[_vm._v("\n            "+_vm._s(_vm.apiError)+"\n          ")]),_vm._v(" "),_c('base-button',{attrs:{"theme":"secondary","uppercase":"","full":""},on:{"click":function($event){_vm.contactModalOpen = true}}},[_vm._v("Napisz do nas")]),_vm._v(" "),_c('contact-modal',{attrs:{"open":_vm.contactModalOpen},on:{"close":_vm.closeBothModals}})],1)])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5 tablet-desktop"},[_c('img',{staticClass:"guest-modal-2__image",attrs:{"src":require("./guestmodal.jpg"),"alt":"","width":"327"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"heading color-primary heading--smaller mb-12"},[_vm._v("\n            Śledź legislację europejską.\n            "),_c('span',{staticClass:"color-primary-darken"},[_c('br'),_vm._v("Zamów moduł dla swojej specjalizacji")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list mb-12"},[_c('li',[_vm._v("\n              aplikacja może też gromadzić dla Ciebie informacje na temat prac\n              legislacyjnych toczących się w instytucjach Unii Europejskiej;\n            ")]),_vm._v(" "),_c('li',[_vm._v("\n              Wybierz jeden z gotowych modułów do przetestowania lub stwórzymy\n              razem nowy – obejmujący projekty z wybranej dziedziny. Będziesz\n              mieć do nich dostęp w wygodnej formie online i otrzymasz raporty\n              mailowe o postępach prac legislacyjnych.\n            ")]),_vm._v(" "),_c('li',[_vm._v("\n              Zapraszamy do kontaktu – wspólnie ustalimy zakres danych, termin\n              i koszt opracowania dodatkowego modułu specjalnie dla Ciebie.\n            ")])])
}]

export { render, staticRenderFns }