export const OTHER_ACT_NOT_MONITORED = ['GIF'];
export const OTHER_ACT_NOT_SEARCHABLE = [];

export const OTHER_ACT_MAP = {
  NFZ: 'healthfund',
  GIF: 'gif',
  'Uchwały RM': 'gov-resolutions',
  'Uchwały Parlamentu': 'parliament-resolutions',
  'Zarządzenia PRM': 'p-m-ordinances',
};
