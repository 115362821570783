<template>
  <div
    class="phone-component"
    ref="phone"
    :style="{
      backgroundImage: `url(${require('@/assets/screenshots/phone-empty.png')})`,
    }"
  >
    <template v-if="loading">
      <div class="loading">
        <div class="spinner"></div>
      </div>
    </template>
    <template>
      <div
        class="items"
        style="padding-bottom: 0px !important;"
        ref="featuredItems"
      >
        <div v-if="featuredItem" class="item featured">
          <div class="item-data">
            <div class="item-heading">
              {{ featuredItem.title }}
            </div>
            <div class="item-details">
              <div>{{ featuredItem.stage_secondary }}</div>
              <div class="mt-2">
                Ostatnia zmiana: {{ formatDate(featuredItem.last_change) }}
              </div>
            </div>
          </div>
          <div class="item-actions">
            <svg
              class="svg-inline--fa fa-chevron-right fa-w-10 subgroup-toggler twa-text-gray-dark"
              style="margin-right: 2px;"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-right"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
              ></path>
            </svg>
            <svg
              data-v-489e9277=""
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="24"
              class="icon-bookmark"
            >
              <path
                data-v-489e9277=""
                fill="none"
                stroke="currentColor"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h12v20l-6-5.18L1 21z"
                class="icon-base__path"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="items regular" :style="{ height: itemsHeight }">
        <template v-if="otherItems.length">
          <div v-for="(item, index) in otherItems" class="item" :key="index">
            <div class="item-data">
              <div class="item-heading">
                {{ item.title }}
              </div>
              <div class="item-details">
                <div>{{ item.stage_secondary }}</div>
                <div class="mt-2">
                  Ostatnia zmiana: {{ formatDate(item.last_change) }}
                </div>
              </div>
            </div>
            <div class="item-actions">
              <svg
                class="svg-inline--fa fa-chevron-right fa-w-10 subgroup-toggler twa-text-gray-dark"
                style="margin-right: 2px;"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                ></path>
              </svg>
              <svg
                data-v-489e9277=""
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="24"
                class="icon-bookmark"
              >
                <path
                  data-v-489e9277=""
                  fill="none"
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h12v20l-6-5.18L1 21z"
                  class="icon-base__path"
                ></path>
              </svg>
            </div>
          </div>
        </template>
        <div class="gradient-overlay"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      items: [],
      itemsHeight: '0px',
    };
  },
  computed: {
    featuredItem() {
      return this.items.length ? this.items[0] : null;
    },
    otherItems() {
      return this.items.length > 1 ? this.items.slice(1) : [];
    },
  },
  methods: {
    getItemsHeight() {
      const phoneHeight = this.$refs.phone.clientHeight;
      const itemsHeight = this.$refs.featuredItems.clientHeight;
      console.log('ITEMS HEIGHT', itemsHeight);
      this.itemsHeight = `${phoneHeight - itemsHeight - 260}px`;
    },
    async fetchData() {
      this.loading = true;
      const response = await fetch(
        'https://www.monitoringregulacyjny.pl/api/v1/lex_header/'
      );
      const data = await response.json();
      if (data.results?.length !== 0) {
        this.items = data.results.slice(0, 4);
      }

      this.loading = false;
      setTimeout(() => {
        this.getItemsHeight();
      }, 100);
    },
    formatDate(value) {
      let date = new Date(value);
      if (isNaN(date)) {
        return value;
      }

      let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są indeksowane od 0
      let year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.loading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 250px;

  .spinner {
    width: 50px; /* Size of the spinner */
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1); /* Light border */
    border-top: 5px solid #0042ff; /* Blue border (animated part) */
    border-radius: 50%; /* Make it circular */
    animation: spin 1s linear infinite; /* Animation settings */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg); /* Start position */
    }
    100% {
      transform: rotate(360deg); /* End position */
    }
  }
}

.phone-component {
  width: 361px;
  min-width: 361px;
  height: 758px;
  min-height: 758px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transform: scale(0.6);
  transition: all 0.4s ease;

  filter: drop-shadow(20px 50px 50px rgba(0, 0, 0, 0.35));
  .items {
    .gradient-overlay {
      position: absolute;
      bottom: 0;
      width: 100%; /* Width of the gradient */
      pointer-events: none; /* Prevent interactions */
      z-index: 2;
      height: 100px;
      background: linear-gradient(to top, white 0%, transparent 100%);
    }

    width: 100%;
    z-index: 1;
    position: relative;
    top: 210px;
    left: 0px;
    padding: 0 28px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    &.regular {
      overflow: hidden;
      margin-top: 15px;
    }
    .item {
      .arrow::before {
        content: '';
        display: inline-block;
        border-top: 2px solid inherit;
        border-right: 2px solid inherit;
        vertical-align: middle;
        -webkit-transition: border-color 0.2s;
        transition: border-color 0.2s;
        width: 0.6rem;
        height: 0.6rem;
        border-width: 3px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2.5rem;
        right: 1.7rem;
        transform: rotate(45deg);
      }
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      gap: 15px;
      background: #f7f7f7;
      padding: 20px;
      font-size: 14px;
      .item-heading {
        font-weight: 700;
      }
      .item-details {
        font-size: 10px;
        margin-top: 20px;
        opacity: 0.4;
        font-weight: 700;
      }
      .item-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .icon-bookmark {
          opacity: 0.4;
        }
      }
      background: #f7f7f7;
      &.featured {
        width: 400px;
        background: rgb(0, 66, 255);
        color: white;
        box-shadow: 0px 0px 20px rgba(0, 66, 255, 0.5);
        .item-details {
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
