<template>
  <div
    class="accordion"
    :class="{
      'accordion--open': open,
      'accordion--error': error,
    }"
    ref="container"
  >
    <button @click="$emit('toggle')" class="accordion__title">
      <slot name="title" />
    </button>
    <div
      v-escape="closeAccordion"
      class="accordion__content text-justify"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    open: Boolean,
    error: Boolean,
  },
  computed: {
    ...mapGetters({
      isAccordionOpen: 'isAccordionOpen',
    }),
  },
  watch: {
    open(open, prevOpen) {
      let accordions = this.isAccordionOpen;
      if (open) {
        accordions.push(this._uid);
      } else {
        accordions = accordions.filter(id => id !== this._uid);
      }
      this.setAccordionOpen(accordions);
      if (open === prevOpen || !open) {
        return;
      }
      this.fixScrollPosition();
    },
    isAccordionOpen(v) {
      if (this.open && v.length === 0) {
        this.$emit('toggle');
      }
    }
  },
  created() {
    if (this.open) {
      this.setAccordionOpen([...this.isAccordionOpen, this._uid]);
    }
  },
  methods: {
    ...mapMutations([
      'setAccordionOpen'
    ]),
    fixScrollPosition() {
      const fromTop = this.$refs.container.getBoundingClientRect().top;
      const nav = document.querySelector('.app__nav-container');
      const offset = nav ? nav.offsetHeight + 10 : 80;
      if (fromTop < offset) {
        window.scrollTo(0, window.scrollY - (offset - fromTop));
      }
    },
    closeAccordion() {
      if (this.open) {
        this.$emit('toggle');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './BaseAccordion';
</style>
