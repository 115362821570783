var render = function render(){var _vm=this,_c=_vm._self._c;return _c('portal',{attrs:{"to":"modal"}},[_c('transition',{attrs:{"name":"modal"},on:{"after-enter":_vm.modalOpen,"after-leave":_vm.modalClose}},[(_vm.open)?_c('div',{directives:[{name:"escape",rawName:"v-escape",value:(() => _vm.$emit('close')),expression:"() => $emit('close')"}],ref:"modalRef",staticClass:"modal",class:{
        'modal--full': _vm.fullHeight,
        'modal--overflowHidden': _vm.overflowHidden,
      },on:{"scroll":e => _vm.scrollListener(e)}},[_c('div',{staticClass:"modal__outer"},[_c('button',{ref:"modalContent",staticClass:"modal__close-bg",on:{"click":function($event){return _vm.$emit('close')}}}),_vm._v(" "),_c('div',{staticClass:"modal__row"},[_c('div',{class:[
              'modal__inner',
              {
                'modal__inner--large': _vm.large,
              },
            ],staticStyle:{"display":"block"}},[_c('div',{staticClass:"modal__close-wrap"},[_c('base-button-exit',{class:{ 'tablet-desktop': _vm.theme !== 'mobileCloseX' },on:{"click":function($event){return _vm.$emit('close')}}})],1),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.theme !== 'mobileCloseX')?_c('div',{staticClass:"modal__close-wrapper-mobile mobile"},[_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require('@/assets/icons/CloseX.svg')}}),_vm._v("\n                ukryj\n              ")])]):_vm._e()],2),_vm._v(" "),_c('div',{class:[
              'modal__side-buttons',
              {
                'modal__side-buttons--active': _vm.currentScrollTop >= 200,
              },
            ]},[_c('div',[(_vm.showCloseSticky)?_c('div',{staticClass:"modal__close-sticky",on:{"click":function($event){return _vm.$emit('close')}}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M18 6L6 18","stroke":"currentColor","stroke-width":"3","stroke-linecap":"round","stroke-linejoin":"round"}}),_vm._v(" "),_c('path',{attrs:{"d":"M6 6L18 18","stroke":"currentColor","stroke-width":"3","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e(),_vm._v(" "),(_vm.showScrollTop)?_c('div',{staticClass:"modal__scroll-top",on:{"click":_vm.scrollTop}},[_c('img',{attrs:{"src":require(`@/assets/icons/ArrowUpward.svg`)}})]):_vm._e(),_vm._v(" "),_c('portal-target',{attrs:{"name":"buttons-sidebar"}})],1)])])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }