import Vue from 'vue';
import VueRouter from 'vue-router';

import { routes } from './routes';
import { scrollBehavior } from './scrollBehavior';
import { guardBeforeEach } from './guardBeforeEach';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior,
});

router.beforeEach(guardBeforeEach);
