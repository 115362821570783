import { router } from '../router/router';
import { api } from '../api/api';
import { getApiToken, setApiToken, removeApiToken } from '../api/token';
import {
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
} from '../api/refreshToken';
import { sessionBroadcast } from '../sessionBroadcast';

const clone = obj => JSON.parse(JSON.stringify(obj));
const initialState = {
  loggedIn: false,
  pk: null,
  email: '',
  username: '',
  name: '',
  phone_number: '',
  vat_number: '',
  company_name: '',
  address_street: '',
  address_city: '',
  address_postcode: '',
  custom_multi_query: [],
  custom_multi_query_reg: [],
  custom_multi_query_nfz: [],
  custom_query_european_union_acts_with_names: [],
  reports_frequency: null,
  summary_frequency: null,
  weekly_summary_day: null,
  monthly_summary_day: null,
  reports_frequency_eu: null,
  summary_frequency_eu: null,
  summary_enabled_eu: null,
  reports_enabled_eu: null,
  weekly_summary_day_eu: null,
  monthly_summary_day_eu: null,
  reports_frequency_news: null,
  summary_frequency_news: null,
  summary_enabled_news: null,
  reports_enabled_news: null,
  weekly_summary_day_news: null,
  monthly_summary_day_news: null,
  poll_in_progress: false,
  otherActs: [],
  ue: [],
  modules: [],
};

export const user = {
  namespaced: true,
  state: clone(initialState),
  getters: {
    isLoggedIn: state => state.loggedIn,
    hasUnfinishedSurvey: state => state.poll_in_progress,
    otherActs: state => (state.loggedIn ? state.otherActs : []),
    noOtherActs: state => state.otherActs.length === 0,
    ue: state => (state.loggedIn ? state.ue : []),
    noUe: state => state.ue.length === 0,
  },
  mutations: {
    setFields(state, fields) {
      Object.assign(state, fields);
    },
    setModules(state, modules) {
      state.modules = modules;
    },
  },
  actions: {
    async updateAccount({ state, commit }, data) {
      commit('setFields', data);
      await api.patch(`v1/account/${state.accountPk}/`, data);
    },
    /* eslint-disable */
    async updateProfile({ state, commit }, data) {
      commit('setFields', data);
      const response = await api.patch(`v1/user_profile/${state.pk}/`, data);
      const fields = {};
      for (let i in response.data) {
        fields[i] = response.data[i];
      }
      commit('setFields', fields);
    },
    async createProfile({ commit, dispatch }, data) {
      const response = await api.post('v1/user_profile/', data);
      commit('setFields', response.data);
      await dispatch('getUser');
    },
    async updateEmail({ state, commit }, { email, password }) {
      let responses = {
        me: null,
        set_username: null,
      };
      const updateUsername = state.username.includes('@');
      const fields = {
        email,
        username: updateUsername ? email : state.username,
      };

      if (updateUsername) {
        responses.set_username = await api.post('auth/users/set_username/', {
          new_username: email,
          current_password: password,
        });
        if (responses.set_username && responses.set_username.status === 204) {
          responses.me = await api.patch('auth/users/me/', {
            email,
          });
        }
      }

      commit('setFields', fields);

      return responses;
    },
    async getUserProfile({ commit }) {
      const response = await api.get('v1/user_profile/', {
        cache: {
          ignoreCache: true,
        },
      });
      const profileData = response.data.results[0];
      if (profileData) {
        const otherActs = [];
        const ue = [];
        profileData.other_acts_access.forEach(act => {
          if (act.includes('UE')) {
            ue.push(act.replace('UE', '').trim());
          } else {
            otherActs.push(act);
          }
        });
        commit('setFields', {
          ...profileData,
          otherActs,
          ue,
        });
      }
    },
    async getUser({ commit }) {
      const responses = await Promise.all([
        api.get('auth/users/me/'),
        api.get('v1/account/'),
        api.get('v1/user_profile/'),
        api.get('v1/modules/'),
      ]);
      const [user, accountData, profile, modules] = responses.map(r => r.data);
      const fields = {
        loggedIn: true,
        email: user.email,
        username: user.username,
      };
      if (profile.results.length) {
        const profileData = profile.results[0];
        const otherActs = [];
        const ue = [];
        profileData.other_acts_access.forEach(act => {
          if (act.includes('UE')) {
            ue.push(act.replace('UE', '').trim());
          } else {
            otherActs.push(act);
          }
        });
        commit('setFields', {
          ...profileData,
          otherActs,
          ue,
        });
        Object.assign(fields, profile.results[0]);
      }
      if (accountData.results.length) {
        const { pk, ...account } = accountData.results[0];
        Object.assign(fields, {
          accountPk: pk,
          ...account,
        });
      }
      commit('setFields', fields);
      commit('setModules', modules.results);
    },
    async refreshToken({ dispatch, state }, options) {
      const refresh = getRefreshToken();
      if (!refresh) {
        if (options && options.initial) {
          return;
        }
        dispatch('logout');
        throw Error('No refresh token!');
      }
      try {
        removeApiToken();
        const response = await api.post('/auth/jwt/refresh', {
          refresh,
        });
        const { data } = response;
        setApiToken(data.access);
        if (!state.loggedIn) {
          await dispatch('getUser');
        }
      } catch (err) {
        dispatch('logout');
        console.log(err);
        throw Error('Token refresh failed!');
      }
    },
    initialLogin({ dispatch }) {
      const sessionToken = getApiToken();
      if (sessionToken) {
        setApiToken(sessionToken);
        return dispatch('getUser');
      }
      return dispatch('refreshToken', {
        initial: true,
      });
    },
    async login(
      { dispatch },
      { username, password, remember, signup = false }
    ) {
      const { data } = await api.post('/auth/jwt/create', {
        username,
        password,
      });
      const { access, refresh } = data;
      setApiToken(access);
      sessionBroadcast.post(access);

      if (remember) {
        setRefreshToken(refresh);
      }
      if (!signup) {
        await dispatch('getUser');
      }
    },
    logout({ state, commit, dispatch }, options) {
      if (!(options && options.initial) && !state.loggedIn) {
        return;
      }
      sessionBroadcast.logout();
      commit('setFields', clone(initialState));
      dispatch('bookmarks/reset', null, { root: true });
      removeApiToken();
      removeRefreshToken();
      api.cache.store = {};
      router.push({
        path: '/login',
        query: { redirect: router.currentRoute.path },
      });
    },
    /* eslint-disable */
    async grantEUAccess({ commit }) {
      const response = await api.post(`v0/grant_eu_access`);
      if (response.status === 200) {
        commit('getUser');
      }
      return response;
    },
  },
};
