var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field",class:{
    'field--light': _vm.theme === 'light',
    'field--light-blue': _vm.theme === 'light-blue',
    'field--white': _vm.theme === 'white',
    'field--error': _vm.error,
    'field--has-value': _vm.hasValue,
    'field--icon': _vm.$slots.icon,
    'field--no-label': !_vm.$slots.default,
  }},[_c('input',_vm._g(_vm._b({ref:"input",staticClass:"input",attrs:{"id":_vm.$attrs.name,"type":_vm.renderedType},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (e => _vm.$emit('enter', e)).apply(null, arguments)}}},'input',_vm.$attrs,false),_vm.inputListeners)),_vm._v(" "),(_vm.$slots.icon)?_c('div',{staticClass:"input-icon"},[_vm._t("icon")],2):_vm._e(),_vm._v(" "),(_vm.type === 'password')?_c('button',{staticClass:"show-password",class:{ 'password-visible': _vm.showPassword },attrs:{"type":"button"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('svg',{staticClass:"show-password__icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 22 14"}},[_c('title',[_vm._v("Show password")]),_vm._v(" "),_c('g',{staticClass:"show-password__off"},[_c('path',{attrs:{"d":"M11 0C5.5 0 0 7 0 7s5.5 7 11 7 11-7 11-7-5.5-7-11-7zm6.3 9c-2.2 1.9-4.4 3-6.3 3-1.8 0-4-1.1-6.2-3-.8-.6-1.5-1.3-2.1-2 .6-.7 1.3-1.4 2-2C7 3.1 9.2 2 11 2c1.8 0 4 1.1 6.3 3 .7.6 1.4 1.3 2 2-.6.7-1.3 1.4-2 2z"}}),_vm._v(" "),_c('circle',{attrs:{"cx":"11","cy":"7","r":"3"}})]),_vm._v(" "),_c('path',{staticClass:"show-password__on",attrs:{"d":"M11 0C5.5 0 0 7 0 7s5.5 7 11 7 11-7 11-7-5.5-7-11-7zM8 7c0-1.7 1.3-3 3-3s3 1.3 3 3-1.3 3-3 3-3-1.3-3-3z"}})])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"label__container"},[(_vm.$slots.default)?_c('label',{staticClass:"label",attrs:{"for":_vm.$attrs.name}},[_c('span',{staticClass:"label__text"},[_vm._t("default"),_vm._v(" "),(_vm.$attrs.required)?_c('span',[_vm._v("*")]):_vm._e()],2)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }